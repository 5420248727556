//import node modules and react modules
import axios from 'axios';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Form, Dropdown, Button } from 'react-bootstrap';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import * as Icons from "react-icons/fa";
import { AiOutlinePlus, AiFillFilter } from "react-icons/ai"
import { BsFillInfoCircleFill } from 'react-icons/bs';
//import DWBO sources
import Headline from '../../../components/Headline';
/* import { selectConfigUserData } from './selectboxConfig' */
import apiConfig from '../../../config/config';
import { responseNotifyHandling } from '../../../components/Error'
import { createClient, updateClient, deleteClient } from '../../../features/user/clientDataManagement'
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { Typeahead } from 'react-bootstrap-typeahead';
import { parseISO } from 'date-fns';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedClient, setSelectedClient } from '../../../features/client/clientSlice';
import { getFormState, setFormState, FormState, getErrors, setErrors, setFormCheckDone, getFormCheckDone, FormCheckDoneOptions, setCurrentAge } from '../../../features/form/formSlice';
import { getFormConfig, getToken, getUser, logout, setRequestLogout } from '../../../features/user/userSlice';
import { MdClear } from 'react-icons/md';
import { containerTypes, getInitDatabaseObject, getDeleteProperties, specialTypes } from '../../../config/configEnums';
import GenericForm from '../../../components/GenericForm';

registerLocale('de', de)
setDefaultLocale('de')

const FormMode = {
    Create: "Create",
    Update: "Update"
}

const initClientService = {
    client: 1, //benötigt die ID von Client
    clientService: "", //benötigt die ID von Service
    clientDateService: ""
}

const initClientProblemArea = {
    client: 1,  //benötigt die ID von Client
    clientProblemArea: "",  //benötigt die ID von ProblemArea
    clientDateProblemArea: ""
}

const initClientObjective = {
    client: 1,  //benötigt die ID von Client
    clientObjective: "",    //benötigt die ID von ProblemArea
    clientDateObjective: ""
}

/**
 * @author Matthias Weise, Elian Plaschnick
 */
function UserDataCollect() {
    const currentUser = useSelector(getUser);
    const fullFormConfig = useSelector(getFormConfig);

    function getDisplayNumberOfFields() {
        for (let i = 0; i < formConfig.containers.length; i++) {
            for (let j = 0; j < formConfig.containers[i].elements?.length; j++) {
                if (formConfig.containers[i].elements[j].specialTypeField === specialTypes.WITHDISPLAYNUMBEROFFIELDS) {
                    const tempObj = { ...formConfig.containers[i].elements[j] };
                    tempObj.containerPosition = formConfig.containers[i].position;
                    return tempObj;
                }
            }
        }

        return null;
    }

    const findFormConfig = () => {
        const urlSplit = window.location.href.split('/');
        let configName = urlSplit[urlSplit.length - 1];

        if (currentUser.userProject.projectType.id === 2) {
            const offerType = currentUser.userProject.projectOffer.projectOfferType?.toLowerCase();

            if (offerType && offerType.includes("freizeitangebot")) {
                configName = configName + "LeisureTime";
            }
        }

        return fullFormConfig[configName];
    }

    const [formConfig, setFormConfig] = useState(findFormConfig());
    const [initCurrentClientData, setInitCurrentClientData] = useState(getInitDatabaseObject(formConfig))
    const [newClient, setNewClient] = useState(initCurrentClientData);

    const [allClients, setAllClients] = useState([]);
    const [filterClientList, setFilterClientList] = useState([]);
    const [updatedClient, setUpdatedClient] = useState(initCurrentClientData);

    const [showModal, setShowModal] = useState(false);
    const [filterSelect, setFilterSelect] = useState([]);
    const [filterTableSelect, setFilterTableSelect] = useState([]);
    const [confirmFor, setConfirmFor] = useState('');
    const [titleModal, setTitleModal] = useState('');
    const [messageModal, setMessageModal] = useState('');
    const [btnCancelModal, setBtnCancelModal] = useState('');
    const [btnSubmitModal, setBtnSubmitModal] = useState('');
    const [btnSubmitColor, setBtnSubmitColor] = useState('danger');

    const [formMode, setFormMode] = useState(FormMode.Create);
    const [radioValue, setRadioValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const [displayNumberOfField, setDisplayNumberOfField] = useState(getDisplayNumberOfFields());

    const tableSelectConfig = formConfig.tableSelectConfig;

    let filterClients = [];

    const tableSelectedClient = useSelector(getSelectedClient);
    const formState = useSelector(getFormState);
    const errors = useSelector(getErrors);
    const formCheckDone = useSelector(getFormCheckDone);

    const dispatch = useDispatch();

    const userToken = useSelector(getToken);

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    //fetched offer type data by db
    useEffect(() => {
        //get all clients
        getClientData();
        dispatch(setFormState(FormState.UNCHANGED))
        dispatch(setErrors({}));

        return () => {
            dispatch(setCurrentAge(""));
        }
    }, []);

    useLayoutEffect(() => {
        let tempFilterSelect = [];
        let tempFilterTableSelect = [];
        for (let i = 0; i < formConfig.containers.length; i++) {
            if (formConfig.containers[i].containerType === containerTypes.GENERICFORM || formConfig.containers[i].containerType === containerTypes.ACCORDION) {
                const filterObjects = formConfig.containers[i].elements.filter(obj => obj.filter || obj.filterNot);
                if (filterObjects.length > 0) {
                    tempFilterSelect = tempFilterSelect.concat(filterObjects);
                }
            }
            else if (formConfig.containers[i].containerType === containerTypes.ADDROWTABLECONTAINER) {
                formConfig.containers[i].tables.forEach((obj) => {
                    obj.elements.forEach(element => {
                        if (element.filter) {
                            const tempConfigElement = tableSelectConfig[obj.tableName][element.value].find(search => search.id === element.filter);
                            const tempFilterTableRow = { ...element, tableName: obj.tableName, containerType: formConfig.containers[i].containerType, selectText: tempConfigElement.value };

                            if (element.filterIf) {
                                const tempConfigFilterIfElement = tableSelectConfig[obj.tableName][element.filterIf[0]].find(search => search.id === element.filterIf[1]);
                                tempFilterTableRow.selectFilterIfText = tempConfigFilterIfElement.value;
                            }
                            tempFilterTableSelect.push(tempFilterTableRow);
                        }
                        else if (element.filterNot) {
                            const tempConfigElement = tableSelectConfig[obj.tableName][element.value].find(search => search.id === element.filterNot);
                            const tempFilterTableRow = { ...element, tableName: obj.tableName, containerType: formConfig.containers[i].containerType, selectText: tempConfigElement.value };

                            if (element.filterIf) {
                                const tempConfigFilterIfElement = tableSelectConfig[obj.tableName][element.filterIf[0]].find(search => search.id === element.filterIf[1]);
                                tempFilterTableRow.selectFilterIfText = tempConfigFilterIfElement.value;
                            }
                            tempFilterTableSelect.push(tempFilterTableRow);
                        }
                    });
                })
            }
        }
        setFilterSelect(tempFilterSelect);
        setFilterTableSelect(tempFilterTableSelect);
    }, []);

    // operations after form is checked
    useEffect(() => {
        if (formCheckDone === FormCheckDoneOptions.YES) {
            if (Object.keys(errors).length === 0) {
                formMode === FormMode.Create ? setConfirmFor("CREATE") : setConfirmFor("UPDATE")

                //confirm dialog
                if (formMode === FormMode.Create) {
                    setMessageModal(newClient.lastName ? `Soll der neue Klient [${newClient.lastName}] gespeichert werden?` :
                        "Soll der neue Klient gespeichert werden?");
                }
                else {
                    setMessageModal(newClient.lastName ? `Sollen die Änderungen am Klienten [${newClient.lastName}] gespeichert werden?` :
                        "Sollen die Änderungen am Klienten gespeichert werden");
                }
                setTitleModal("Ungesicherte Änderungen");
                setBtnCancelModal("Abbrechen");
                setBtnSubmitModal("Speichern");
                setBtnSubmitColor("success");
                setShowModal(true);
            }

            dispatch(setFormCheckDone(""));
        }
    }, [formCheckDone]);

    useEffect(() => {
        if (tableSelectedClient !== null) {
            if (newClient?._id === tableSelectedClient._id) {
                dispatch(setSelectedClient(null));
            }
        }
    }, [newClient]);

    useEffect(() => {
        const handler = event => {
            event.preventDefault();
            event.returnValue = '';
        };
        // if the form is NOT unchanged, then set the onbeforeunload
        if (formState !== FormState.UNCHANGED) {
            window.addEventListener('beforeunload', handler);
            // clean it up, if the dirty state changes
            return () => {
                window.removeEventListener('beforeunload', handler);
            };
        }
        // since this is not dirty, don't do anything
        return () => { };
    }, [formState]);

    async function getClientData() {
        setLoading(true);
        document.body.style.cursor = "progress";
        //get all clients
        await axios.get(`${apiConfig.rest}/user/data/listClients`, config).then((response) => {
            response.data.sort((a, b) => {
                return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
            });
            setAllClients(response.data);
            if (tableSelectedClient && Object.keys(newClient).every(element => Object.keys(tableSelectedClient).includes(element))) {
                handleSelectClient(tableSelectedClient, true);
            }
            setLoading(false);
            document.body.style.cursor = "auto";
        }).catch((err) => {
        });

        await axios.get(`${apiConfig.rest}/user/data/client`, config).then((response) => {
            setFilterClientList(response.data);
        }).catch((err) => {
        });
    }

    function handleChange(e, check) {
        dispatch(setFormState(FormState.MODIFIED));
        if (!check) {
            setNewClient({ ...newClient, [e.target.name]: e.target.value });
        }
        else {
            setNewClient({ ...newClient, [e.target.name]: e.target.checked });
        }
    }

    function calcAgeFormula(birthday) {
        var todayDate = new Date();
        var age = todayDate.getFullYear() - birthday.getFullYear();
        var m = todayDate.getMonth() - birthday.getMonth();
        if (m < 0 || (m === 0 && todayDate.getDate() < birthday.getDate())) {
            age--;
        }

        return age;
    }

    const clientCreate = () => {
        let createThisClient = { ...newClient, project: currentUser.userProject._id, collaborator: currentUser._id }
        createThisClient = handleClientData(createThisClient);

        createClient(createThisClient, config)
            .then((res) => {
                if (res.status === 201) {
                    responseNotifyHandling(res, res.status, newClient.lastName ? `Klient*in: [${newClient.lastName}] wurde erstellt` :
                        "Klient*in wurde erstellt");
                    handleSelectClient(res.data, true);
                    getClientData();
                    dispatch(setFormState(FormState.UNCHANGED));
                }
            })
            .catch((err) => {
                responseNotifyHandling(err.response, err.response.status, newClient.lastName ? `Fehler [${err.response.status}] beim erstellen vom Klienten ${newClient.lastName}.` :
                    `Fehler [${err.response.status}] beim erstellen vom Klienten.`);
            });
    }

    const clientUpdate = () => {
        const updateThisClient = handleClientData(newClient);

        updateClient(updateThisClient, config)
            .then((res) => {

                if (res.status === 200) {
                    responseNotifyHandling(res, res.status, newClient.lastName ? `Klient*in: [${newClient.lastName}] wurde aktualisiert` :
                        "Klient*in wurde aktualisiert");
                    getClientData();
                    setUpdatedClient(updateThisClient);
                    setNewClient(updateThisClient);
                    dispatch(setFormState(FormState.UNCHANGED));
                }
            })
            .catch((err) => {
                responseNotifyHandling(err.response, err.response.status, newClient.lastName ? `Fehler [${err.response.status}] beim aktualisieren vom Klienten ${newClient.lastName}.` :
                    `Fehler [${err.response.status}] beim aktualisieren vom Klienten.`);
            });
    }

    const clientDelete = () => {
        const deleteThisClient = { ...newClient };

        deleteClient(newClient, config)
            .then((res) => {
                if (res.status == 204) {
                    responseNotifyHandling(res, res.status, newClient.lastName ? `Klient*in: [${newClient.lastName}] wurde gelöscht` :
                        "Klient*in wurde gelöscht");
                    dispatch(setFormState(FormState.UNCHANGED));
                    resetForm();
                }
            })
            .catch((err) => {
                responseNotifyHandling(err.response, err.response.status, newClient.lastName ? `Fehler [${err.response.status}] beim löschen vom Klienten ${newClient.lastName}.` :
                    `Fehler [${err.response.status}] beim löschen vom Klienten.`)
            });
    }

    const resetForm = () => {
        getClientData();
        setNewClient(initCurrentClientData);
        setUpdatedClient(initCurrentClientData);
        dispatch(setCurrentAge(""));
        setFormMode(FormMode.Create);
    }

    const handleDeleteClientData = (e) => { //Delete Client, Client-Objectives etc
        setConfirmFor("DELETE");

        setMessageModal(newClient.lastName ? `Soll der Klient [${newClient.lastName}] gelöscht werden?` :
            "Soll der Klient gelöscht werden?");
        setTitleModal("Klient löschen");
        setBtnCancelModal("Abbrechen");
        setBtnSubmitModal("Löschen");
        setBtnSubmitColor("danger");
        setShowModal(true);
    }

    async function handleSelectClient(element, create) {
        let client;
        if (create) {
            client = element;
        }
        else {
            await axios.get(`${apiConfig.rest}/user/data/client/${element._id}`, config).then((response) => {
                if (response.status === 200) {
                    client = response.data
                }
            }).catch((err) => {
                responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}]: Klienten konnten nicht gefunden werden.`)
            });
        }

        setUpdatedClient(client);
        setNewClient(client);
        if (client?.birthday) {
            dispatch(setCurrentAge(calcAgeFormula(parseISO(client.birthday))));
        }
        setFormMode(FormMode.Update);
        dispatch(setErrors({}));
    }

    const handleRadioClick = (e, field) => {
        if (field.value === radioValue?.value) {
            setRadioValue(null);
        }
        else {
            setRadioValue(field);
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }
    const openModal = () => {
        setShowModal(true);
    }

    /**
     * Entfernt leere Zeilen aus den Klienten Tabellen
     * 
     * @param {*} client Klient Objekt
     * @returns Klienten Objekt ohne leere Zeilen
     */
    const removeNullRows = (client) => {
        let noNullRows = { ...client }

        const tableContainer = formConfig.containers.find(container => container.containerType === containerTypes.ADDROWTABLECONTAINER);

        for (let i = 0; i < tableContainer.tables.length; i++) {
            const requiredField = tableContainer.tables[i].elements.find(obj => obj.required === true);

            if (requiredField) {
                noNullRows[tableContainer.tables[i].tableName] = client[tableContainer.tables[i].tableName].filter(item => item[requiredField.value] !== "");
            }
        }

        return noNullRows;
    }

    /**
     * Löscht die Properties der deaktivierten Input Felder aus dem Klienten Objekt
     * 
     * @param {*} client Klient Objekt
     * @returns Klienten Objekt mit gelöschten Properties
     */
    const resetDisabledData = (client) => {
        const deleteProperties = getDeleteProperties(formConfig, newClient, initCurrentClientData);
        const editedClient = { ...client, ...deleteProperties };
        return editedClient;
    }

    /**
     * Bearbeitet alle Nachbearbeitungen von 'newClient'
     * 
     * @param {*} client Klienten Objekt
     * @returns Bearbeiteter Klient
     */
    const handleClientData = (client) => {
        let editedClient;
        editedClient = removeNullRows(client)
        editedClient = resetDisabledData(editedClient);
        if (displayNumberOfField) {
            editedClient = removeDisplayNumberOfFields(editedClient);
        }

        return editedClient;
    }

    const removeDisplayNumberOfFields = (client) => {
        if (displayNumberOfField) {
            if (!client[displayNumberOfField.depends]) {
                client[displayNumberOfField.value] = [];
            }
            else if (client[displayNumberOfField.depends] < client[displayNumberOfField.value].length) {
                client[displayNumberOfField.value] = client[displayNumberOfField.value].slice(0, client[displayNumberOfField.depends]);
            }
        }

        return client;
    }

    const handleConfirmModal = () => {
        dispatch(setFormState(FormState.SAVING));
        switch (confirmFor) {
            case "CREATE":
                clientCreate();
                break;
            case "UPDATE":
                clientUpdate();
                break;
            case "DELETE":
                clientDelete();
                break;
            default:
                //TODO
                break;
        }
        closeModal();
    }

    function getTime(key) {
        if (newClient[key]) {
            if (typeof newClient[key] == "string") {
                return parseISO(newClient[key]);
            }
            if (typeof newClient[key] == "object") {
                return newClient[key];
            }
        }
        else return '';
    }

    function getFilteredClients() {
        if (radioValue) {
            let clientsFiltered;

            if (radioValue.containerType === containerTypes.ADDROWTABLECONTAINER) {
                clientsFiltered = filterClientList.filter(client => {
                    for (let i = 0; i < client[radioValue.tableName]?.length; i++) {
                        if (radioValue.filter) {
                            if (radioValue.filterIf) {
                                if (client[radioValue.tableName][i][radioValue.filterIf[0]] === radioValue.filterIf[1] && client[radioValue.tableName][i][radioValue.value] === radioValue.filter) {
                                    return true;
                                }
                            }
                            else if (client[radioValue.tableName][i][radioValue.value] === radioValue.filter) {
                                return true;
                            }
                        }
                        else if (radioValue.filterNot) {
                            if (radioValue.filterIf) {
                                if (client[radioValue.tableName][i][radioValue.filterIf[0]] === radioValue.filterIf[1] && client[radioValue.tableName][i][radioValue.value] === radioValue.filterNot) {
                                    return false;
                                }
                            }
                            else if (client[radioValue.tableName][i][radioValue.value] === radioValue.filterNot) {
                                return false;
                            }
                        }
                    }
                    return radioValue.filter ? false : true;
                })
            }
            else {
                clientsFiltered = filterClientList.filter(client => {
                    return client[radioValue.value] === radioValue.filter;
                })
            }

            filterClients = clientsFiltered;
            return clientsFiltered;
        }
    }

    function typeaheadPlaceholder() {
        if (loading) {
            return "";
        }
        else if (allClients.length === 0) {
            return "Keine Klienten gefunden";
        }
        else if (radioValue && filterClients.length === 0) {
            return "Keine Klienten gefunden";
        }
        return "-- Klient*in auswählen --";
    }

    const functions = { handleChange };

    return (
        <div style={{ width: '100%' }}>
            <ConfirmationDialog
                showModal={showModal}
                confirmModal={() => handleConfirmModal()}
                hideModal={() => closeModal()}
                message={messageModal}
                title={titleModal}
                btnCancelTxt={btnCancelModal}
                btnSubmitTxt={btnSubmitModal}
                btnSubmitColor={btnSubmitColor}
            />
            <Headline name='Datenerfassung' />
            <Form onSubmit={(e) => {
                e.preventDefault();
            }}>
                <div className='mt-4' style={{ width: '100%' }}>
                    <h5 className='mt-3 mb-4 mx-auto p-0' style={{ width: "fit-content" }}>{formMode === FormMode.Create ? "Klient*in erstellen" : "Klient*in aktualisieren"}</h5>
                    <div className='d-flex p-0 mx-auto' style={{ width: "100%" }}>
                        <div className='me-3'>
                            <Button
                                onClick={() => dispatch(setFormCheckDone(FormCheckDoneOptions.CHECKING))}
                                variant="success">
                                <Icons.FaSave
                                    style={{
                                        margin: "0 0.24rem 0.15rem 0"
                                    }} />Speichern</Button>
                        </div>
                        {formMode === FormMode.Update &&
                            <div>
                                <Button
                                    variant='danger'
                                    onClick={(e) => handleDeleteClientData(e)}><Icons.FaTrash size={15} style={{ transform: 'translate(-0.2rem, -0.13rem)' }} />Datensatz löschen</Button>
                            </div>
                        }
                        <div className='ms-auto d-flex'>
                            {radioValue &&
                                <Button
                                    className='clear-filter'
                                    onClick={() => setRadioValue(null)}>
                                    <MdClear size="19" style={{ transform: "translate(-0.25rem, -0.09rem)" }} />
                                    Filter entfernen
                                </Button>
                            }
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    <AiFillFilter style={{ transform: 'translate(-0.2rem, -0.09rem)' }} color='white' />Filter
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <FormControl id="filter-dropdown" component="fieldset">
                                        <FormLabel component="legend"><span>Tabellen Filter</span></FormLabel>
                                        <RadioGroup
                                            id="filter-service-container"
                                            aria-label='Tabellen Filter'
                                            name="Tabellen Filter"
                                        >
                                            {filterTableSelect.map((field, i) => {
                                                return (
                                                    <FormControlLabel
                                                        key={i}
                                                        label={field.filter ? `${field.selectFilterIfText ? ("Bei " + field.selectFilterIfText) : ""} "${field.selectText}" in ${field.name}` : `Ohne ${field.selectText} im Berichtsjahr`}
                                                        value={field.value}
                                                        control={<Radio size='small' checked={radioValue?.value === field.value} onClick={(e) => handleRadioClick(e, field)} />}
                                                    />
                                                )
                                            })
                                            }
                                        </RadioGroup>
                                        <FormLabel component="legend"><span>Keine Angabe</span></FormLabel>
                                        <RadioGroup
                                            id="filter-check-container"
                                            aria-label='keine angabe'
                                            name="keine angabe"
                                        >
                                            {filterSelect.map((field, i) => {
                                                return (<FormControlLabel
                                                    key={i}
                                                    label={field.name}
                                                    value={field.value}
                                                    id={`${field.name}Check`}
                                                    control={<Radio size='small' checked={radioValue?.value === field.value} onClick={(e) => handleRadioClick(e, field)} />}
                                                />)
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='col'>
                        <Form.Group>
                        </Form.Group>
                    </div>
                </div>
                <div style={{ margin: "1.9rem 0 1.7rem 0" }}>
                    {radioValue &&
                        <p id="filter-info-text"><BsFillInfoCircleFill size="15" style={{ transform: "translate(-0.25rem, -0.09rem)" }} />Filter aktiv</p>
                    }
                    <div style={{ height: "2.7rem", width: "100%", margin: "0 auto", position: "relative" }} className={`row ${radioValue ? "filter-active" : ""}`}>
                        <Typeahead
                            key={Object.values(updatedClient)}
                            id="clientList"
                            controlId="clientList"
                            options={radioValue ? getFilteredClients() : allClients}
                            onChange={(element) => {
                                if (element.length !== 0) {
                                    handleSelectClient(element[0]);
                                    setFormMode(FormMode.Update);
                                }
                            }}
                            onInputChange={(element) => {
                                if (element === "") {
                                    resetForm();
                                }
                            }}
                            labelKey={(option) => {
                                if (option.firstName) {
                                    return `${option.lastName}, ${option.firstName}`;
                                }
                                else {
                                    return option.lastName;
                                }
                            }}
                            defaultSelected={[updatedClient]}
                            placeholder={typeaheadPlaceholder()}
                            disabled={allClients.length === 0}
                        />
                        {updatedClient.lastName &&
                            <Button
                                style={{ position: "absolute", width: "fit-content", right: 10, top: 2.2 }}
                                className='clear-filter'
                                onClick={() => resetForm()}>
                                <MdClear size="23" />
                            </Button>
                        }
                    </div>
                </div>
                <GenericForm
                    formConfig={formConfig}
                    databaseObject={newClient}
                    setDatabaseObject={setNewClient}
                    displayNumberOfField={displayNumberOfField}
                    searchField={true}
                />
            </Form>
        </div>
    );
}

export default UserDataCollect;