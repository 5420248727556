import React, { useState } from "react";
import { useSelector } from "react-redux";
import Headline from "../../../components/Headline";
import Datepicker from "../../../components/Datepicker";
import { Form } from "react-bootstrap";
import { Button, Alert } from "@mui/material";
import { getFormConfig, getToken, getUser } from "../../../features/user/userSlice";
import { useEffect } from "react";
import Table from "../../../components/table/Table";
import moment from "moment/moment";
import FileSaver from "file-saver";
import dayjs from "dayjs";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { CellFill, HorizontalCellAlignment, IgrExcelModule, Workbook, WorkbookFormat, CellBorderLineStyle, WorkbookColorInfo } from "igniteui-react-excel";
import { ExcelUtility } from "./ExcelUtility";
import { VerticalAlignment } from "igniteui-react-core";
import { inputTypes, reportYear } from "../../../config/configEnums";
import apiConfig from "../../../config/config";
import axios from "axios";
import { tableSelectNonPersonalConfig } from "../../../forms/BH/disabilityAssistanceNonPersonalSelect";
import { tableSelectConfig } from "../../../forms/BH/disabilityAssistanceSelect";
import { current } from "@reduxjs/toolkit";
import { formEventSelect } from "../../../forms/BH/events/formEventSelect";
import { getClientsByDates } from "../../../features/user/clientDataManagement";

IgrExcelModule.register();

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const initForm = {
    exportFrom: new Date(Number(reportYear), 0, 1),
    exportTo: new Date()
}

let row;

/**
 * @author Elian Plaschnick
 */
const ExcelExport = () => {
    const minDate = dayjs(`January 1, ${reportYear}`);
    const maxDate = dayjs(`December 31, ${reportYear}`);

    const userToken = useSelector(getToken);
    const user = useSelector(getUser);
    const formConfig = useSelector(getFormConfig);

    const [form, setForm] = useState(initForm);
    const projectType = user.userProject.projectType.id;

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    const buildTargetAudience = (ws, row) => {
        switch (user.userProject.projectType.id) {
            case 1:
                const mergedRowWH = ws.mergedCellsRegions().add(8, 0, 8, 1);
                mergedRowWH.value = "Wohnungslose und von Wohnungslosigkeit bedrohte Personen"
                row = 9;
                break;

            case 2:
                const mergedCell1 = ws.mergedCellsRegions().add(8, 0, 8, 1);
                const mergedCell2 = ws.mergedCellsRegions().add(9, 0, 9, 1);
                mergedCell1.value = "Das Projekt richtet sich an";
                mergedCell2.value = "Menschen mit";
                mergedCell1.cellFormat.font.bold = true;
                mergedCell2.cellFormat.font.bold = true;
                mergedCell1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                mergedCell2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                row = 10;

                formConfig.project.containers.forEach((container) => {
                    if (container.name?.toLowerCase().includes("zielgruppe")) {
                        container.elements.forEach((field) => {
                            if (field.inputType === inputTypes.CHECKBOX) {
                                const labelCell = ws.rows(row).cells(0);
                                const valueCell = ws.rows(row).cells(1);
                                labelCell.value = field.name;
                                valueCell.value = user.userProject[field.value] ? "Ja" : "Nein";
                                valueCell.cellFormat.alignment = HorizontalCellAlignment.Right;
                                row++
                            }
                        })
                    }
                })
                break;

            case 3:
                const mergedRowSFH1 = ws.mergedCellsRegions().add(8, 0, 8, 1);
                const mergedRowSFH2 = ws.mergedCellsRegions().add(9, 0, 9, 1);
                mergedRowSFH1.value = "Straffällige Personen, die derzeit eine Haftstrafe verbüßen im Rahmen der Entlassungsvorbereitung";
                mergedRowSFH2.value = "Haftentlassene, die aus eigener Kraft nicht in der Lage sind, ihre Schwierigkeiten zu überwinden"
                row = 10;
                break;

            default:
                console.log("no projecttype in buildTagetAudience()")
                return null;
        }

        const mergedRowH = ws.mergedCellsRegions().add(row, 0, row, 1);
        row++

        mergedRowH.value = "2. Soziodemografische Daten der Nutzer*innen";
        mergedRowH.cellFormat.font.bold = true;
        mergedRowH.cellFormat.fill = CellFill.createSolidFill("#bfbfbf");

        return row;
    }

    const createWorkbook = () => {
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("SSB-TeilC");
        let currentRow = 0;
        ws.columns(0).width = 23000;
        ws.columns(1).width = 5000;
        ws.columns(2).width = 4000;
        ws.columns(3).width = 4000;
        ws.columns(4).width = 4500;

        const mergedRow1 = ws.mergedCellsRegions().add(0, 0, 0, 1);
        const mergedRow2 = ws.mergedCellsRegions().add(1, 0, 2, 0);
        const mergedRow3 = ws.mergedCellsRegions().add(3, 0, 3, 1);
        const mergedRow4 = ws.mergedCellsRegions().add(4, 0, 4, 1);
        const mergedRow5 = ws.mergedCellsRegions().add(5, 0, 5, 1);
        const mergedRow6 = ws.mergedCellsRegions().add(6, 0, 6, 1);
        const mergedRow7 = ws.mergedCellsRegions().add(7, 0, 7, 1);

        mergedRow1.value = "Teil C - Angebotsspezifischer Teil - Klienten- und Leistungsstatistik";
        mergedRow2.value = "Quantitative Angaben des Projektes innerhalb des Berichtszeitraums";
        mergedRow3.value = "Angebot";
        if (user.userProject.projectType.id === 2) {
            if (user.userProject.projectOffer.projectOfferType.toLowerCase().includes("beratung")) {
                mergedRow4.value = "3.2.1 Beratungsstellen";
            }
            else if (user.userProject.projectOffer.projectOfferType.toLowerCase().includes("freizeit")) {
                mergedRow4.value = "3.3.1 Freizeitangebote";
            }
            else {
                mergedRow4.value = user.userProject.projectOffer.projectOfferType;
            }
        }
        else {
            mergedRow4.value = user.userProject.projectOffer.projectOfferType;
        }
        mergedRow5.value = "Name des Trägers (ggf. Abkürzung)";
        mergedRow6.value = user.userProject.projectNumber;
        mergedRow7.value = "1. Zielgruppe";

        mergedRow1.cellFormat.font.bold = true;
        mergedRow1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        mergedRow2.cellFormat.font.bold = true;
        mergedRow2.cellFormat.verticalAlignment = VerticalAlignment.Top;
        mergedRow3.cellFormat.font.bold = true;
        mergedRow4.cellFormat.font.bold = true;
        mergedRow4.cellFormat.fill = CellFill.createSolidFill("#f4b084");
        mergedRow5.cellFormat.alignment = HorizontalCellAlignment.Right;
        mergedRow6.cellFormat.alignment = HorizontalCellAlignment.Right;
        mergedRow7.cellFormat.font.bold = true;
        mergedRow7.cellFormat.fill = CellFill.createSolidFill("#bfbfbf")

        currentRow = buildTargetAudience(ws, currentRow)

        return [wb, currentRow];
    }

    const [clients, setClients] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => {
        if (form.exportFrom && form.exportTo && form.exportFrom?.getFullYear() == reportYear && form.exportTo?.getFullYear() == reportYear) {
            if (form.exportFrom < form.exportTo) {
                const dates = { ...form, reportYear: reportYear };
                dates.exportFrom = dates.exportFrom.setTime(dates.exportFrom.getTime() + (1 * 60 * 60 * 1000));
                dates.exportTo = dates.exportTo.setTime(dates.exportTo.getTime() + (1 * 60 * 60 * 1000));
                getData(dates);
            }
            else {
                setClients(null);
                setError("Das Datum im 'Von' Feld muss kleiner sein, als das Datum im 'Bis' Feld");
            }
        }
        else {
            setClients(null);
        }
    }, [form]);

    const getData = (dates) => {
        getClientsByDates(dates, userToken)
            .then((response) => {
                setClients(response.data);
            })
            .catch((err) => {
                console.log(err);
                setClients([]);
            })
    }

    const workbookSave = (worksheet) => {
        ExcelUtility.save(worksheet, "SSB-TeilC").then(
            (f) => {
                console.log("Saved:" + f);
            },
            (err) => {
                console.error("ExcelUtility.Save Error:" + err);
            }
        );
    }

    const handleChange = (e) => {
        if (e.target.value) {
            setForm({ ...form, [e.target.name]: e.target.value.toDate() });
        }
        else {
            setForm({ ...form, [e.target.name]: null })
        }

        if (error) {
            setError("");
        }
    }

    function getTableSelectConfig() {
        if (user.userProject.projectType.id !== 1) {
            return formConfig.dataCollection.tableSelectConfig;
        }

        const offerType = user.userProject.projectOffer.projectOfferType.toLowerCase()
        if (offerType.includes('notuebernachtung fuer familien') || offerType.includes('notübernachtung für familien')) {
            return formConfig.dataCollection.tableSelectOvernightFamilyConfig;
        }
        else if (offerType.includes('notuebernachtung') || offerType.includes('notübernachtung')) {
            return formConfig.dataCollection.tableSelectOvernightConfig;
        }
        else if (offerType.includes('housing first')) {
            return formConfig.dataCollection.tableSelectHouseVisitConfig;
        }
        else if (offerType.includes('medizinische versorgung')) {
            return formConfig.dataCollection.tableSelectMedicalCareConfig;
        }
        else if (offerType.includes('psychologische beratungsstelle')) {
            return formConfig.dataCollection.tableSelectPsychologicalCounselingConfig;
        }
        else {
            return formConfig.dataCollection.tableSelectConfig;
        }
    }

    async function addAgeToWorkbook(dates, currentRow, wb) {
        await axios.post(`${apiConfig.rest}/user/data/ageExcelExport`, { dates }, config)
            .then((res) => {
                const ws = wb.worksheets("SSB-TeilC");
                const header1 = ws.rows(currentRow).cells(0);
                const header2 = ws.rows(currentRow).cells(1);
                const from = ws.getCell("B2");
                const to = ws.getCell("B3");
                currentRow++;

                header1.value = "Alter";
                header2.value = "Anzahl";
                from.value = dayjs(form.exportFrom).format('DD.MM.YYYY');
                to.value = dayjs(form.exportTo).format('DD.MM.YYYY');

                header1.cellFormat.font.bold = true;
                header2.cellFormat.font.bold = true;
                header1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                header2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                from.cellFormat.font.bold = true;
                to.cellFormat.font.bold = true;

                const ages = res.data;

                let sum = 0;

                ages.forEach((age) => {
                    const [key, value] = Object.entries(age)[0];
                    if (key !== "_id") {
                        const cell1 = ws.rows(currentRow).cells(0);
                        const cell2 = ws.rows(currentRow).cells(1);
                        currentRow++;
                        sum += value;

                        cell1.value = key;
                        cell2.value = value;
                    }
                });
                const sumCell1 = ws.rows(currentRow).cells(0);
                const sumCell2 = ws.rows(currentRow).cells(1);

                sumCell1.value = "Gesamt";
                sumCell2.value = sum;

                sumCell1.cellFormat.font.bold = true;
                sumCell2.cellFormat.font.bold = true;
                currentRow += 2;
            })

        return [wb, currentRow];
    }

    async function addSociodemographicData(dates, currentRow, workbook) {
        if (projectType === 2 && user.userProject.projectOffer.projectOfferType.toLowerCase().includes("freizeitangebot")) {
            dates.formConfig = formConfig.dataCollectionLeisureTime;
        }
        else {
            dates.formConfig = formConfig.dataCollection;
        }
        let fields;
        await axios.post(`${apiConfig.rest}/user/data/excelExport`, { dates }, config)
            .then((res) => {
                const ws = workbook.worksheets("SSB-TeilC");
                fields = res.data;
                let combineCommunicationFlag = false;
                fields.forEach((field) => {
                    const [key, value] = Object.entries(field)[0];
                    let fieldObj;
                    if (key === "communicationMerge") {
                        fieldObj = { inputType: inputTypes.CHECKBOX, name: "Kommunikationsform" }
                    }
                    else {
                        fieldObj = getField(key, dates.formConfig);
                    }
                    if (fieldObj) {
                        const headerCell1 = ws.rows(currentRow).cells(0);
                        const headerCell2 = ws.rows(currentRow).cells(1);

                        switch (fieldObj.name.toLowerCase()) {
                            case "erstkontakt":
                                headerCell1.value = "Herkunft der Beratungsanfragen";
                                break;

                            case "unterstützungsform":
                                headerCell1.value = "Vorrangige Unterstützungsform"
                                break;

                            default: headerCell1.value = fieldObj.name;
                        }

                        headerCell2.value = "Anzahl";

                        headerCell1.cellFormat.font.bold = true;
                        headerCell2.cellFormat.font.bold = true;
                        headerCell1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                        headerCell2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                        currentRow++;

                        switch (fieldObj.inputType) {
                            case inputTypes.SELECT:
                                const selections = formConfig.dataCollection.selectConfigUserData[key];
                                let sum = 0;
                                if (projectType === 2 && key === "arrangedBy") {
                                    let serviceProviderCell = {};
                                    let serviceProviderValue = 0;
                                    selections.forEach((selection) => {
                                        if (selection.id === 7) {
                                            const serviceProviderKey = ws.rows(currentRow).cells(0);
                                            serviceProviderCell = ws.rows(currentRow).cells(1);

                                            serviceProviderKey.value = "Leistungserbringer gesamt";
                                            serviceProviderCell.cellFormat.font.bold = true;
                                            currentRow++;
                                        }

                                        const countObj = value.find((obj) => obj._id === selection.id);
                                        const cellValue = countObj ? countObj.count : 0;
                                        if (selection.id >= 7 && selection.id <= 11) {
                                            serviceProviderValue += cellValue;
                                        }

                                        const keyCell = ws.rows(currentRow).cells(0);
                                        const valueCell = ws.rows(currentRow).cells(1);

                                        keyCell.value = selection.id >= 7 && selection.id <= 11 ? `   Davon ${selection.value}` : selection.value;
                                        valueCell.value = cellValue;

                                        sum += cellValue;
                                        currentRow++
                                    })
                                    serviceProviderCell.value = serviceProviderValue;
                                }
                                else {
                                    selections.forEach((selection) => {
                                        const countObj = value.find((obj) => obj._id === selection.id);
                                        const cellValue = countObj ? countObj.count : 0;

                                        const keyCell = ws.rows(currentRow).cells(0);
                                        const valueCell = ws.rows(currentRow).cells(1);

                                        keyCell.value = selection.value;
                                        valueCell.value = cellValue;

                                        sum += cellValue;
                                        currentRow++
                                    })
                                }
                                const sumCell1 = ws.rows(currentRow).cells(0);
                                const sumCell2 = ws.rows(currentRow).cells(1);

                                sumCell1.value = "Gesamt";
                                sumCell2.value = sum;

                                sumCell1.cellFormat.font.bold = true;
                                sumCell2.cellFormat.font.bold = true;

                                if (projectType === 2 && key === "nationality") {
                                    currentRow++;
                                    const notGermanWomen = value.find((obj) => obj._id === "notGermanWomen");
                                    const notGermanWomenCell1 = ws.rows(currentRow).cells(0);
                                    const notGermanWomenCell2 = ws.rows(currentRow).cells(1);

                                    notGermanWomenCell1.value = "   davon Frauen ohne Deutsche Staatsangehörigkeit";
                                    notGermanWomenCell2.value = notGermanWomen ? notGermanWomen.notGermanWomen : 0;
                                }
                                if (projectType === 2 && key === "priorityDisability") {
                                    currentRow++;
                                    const multipleDisabilities = value.find((obj) => obj._id === "multipleDisabilities");
                                    const multipleDisabilitesCell1 = ws.rows(currentRow).cells(0);
                                    const multipleDisabilitesCell2 = ws.rows(currentRow).cells(1);

                                    multipleDisabilitesCell1.value = "   davon mehrfache Behinderung";
                                    multipleDisabilitesCell2.value = multipleDisabilities ? multipleDisabilities.multipleDisabilities : 0;
                                }
                                currentRow += 2;
                                break;

                            case inputTypes.NUMBER:
                            case inputTypes.CHECKBOX:
                                const keyCell = ws.rows(currentRow).cells(0);
                                const valueCell = ws.rows(currentRow).cells(1);

                                keyCell.value = key === "communicationMerge" ? "Klient*innen, die Gebärdensprache oder andere Kommunikationsformen nutzen" : fieldObj.name;
                                valueCell.value = value[0].count;
                                currentRow += 2;
                                break;

                            default:
                                console.log("Unknown inputType in addSociodemograohicData");
                        }
                    }
                })
            })

        return [workbook, currentRow, fields];
    }

    function getField(value, config) {
        let output;
        config.containers.some((container) => {
            output = container.elements?.find((field) => field.value === value);
            return output;
        });

        return output;
    }

    function getTableField(name) {
        const config = formConfig.dataCollection;
        let output;
        config.containers.some((container) => {
            const tmp = container.tables?.some((table) => {
                output = table.elements.find((field) => field.name === name);
                if (output) {
                    output = { ...output, tableName: table.tableName };
                }
                return output;
            })
            return tmp;
        })

        return output;
    }

    function addTableData(currentRow, workbook, fields) {
        const tableSelection = getTableSelectConfig();

        if (fields) {
            const ws = workbook.worksheets("SSB-TeilC");

            const header = ws.mergedCellsRegions().add(currentRow, 0, currentRow, 1);
            header.value = "3. Leistungsdaten des Projekts";

            header.cellFormat.font.bold = true;
            header.cellFormat.fill = CellFill.createSolidFill("#bfbfbf");

            currentRow += 2;

            if (projectType === 2) {
                const tableData = fields.find((field) => field.Leistung)
                currentRow = addDisabilityAssistanceTable(tableData, ws, currentRow);
            }
            else {
                fields.forEach((field) => {
                    const [key, value] = Object.entries(field)[0];
                    const tableField = getTableField(key);

                    if (tableField) {
                        const headerCell1 = ws.rows(currentRow).cells(0);
                        const headerCell2 = ws.rows(currentRow).cells(1);

                        headerCell1.value = key;
                        headerCell2.value = "Anzahl";

                        headerCell1.cellFormat.font.bold = true;
                        headerCell2.cellFormat.font.bold = true;
                        headerCell1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                        headerCell2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

                        currentRow++;

                        const selections = tableSelection[tableField.tableName][tableField.value];

                        selections.forEach((selection) => {
                            const countObj = value.tableData.find((obj) => obj._id === selection.id);
                            const cellValue = countObj ? countObj.count : 0;

                            const keyCell = ws.rows(currentRow).cells(0);
                            const valueCell = ws.rows(currentRow).cells(1);

                            keyCell.value = selection.value;
                            valueCell.value = cellValue;

                            currentRow++
                        })
                        if (key !== "Leistung") {
                            const sumCell1 = ws.rows(currentRow).cells(0);
                            const sumCell2 = ws.rows(currentRow).cells(1);

                            sumCell1.value = "Klient*innen gesamt";
                            sumCell2.value = value.clientSum;

                            sumCell1.cellFormat.font.bold = true;
                            sumCell2.cellFormat.font.bold = true;
                            currentRow++;
                        }
                    }
                })
            }
        }
        else {
            console.log("no fields in addTableData()")
        }

        return [workbook, currentRow];
    }

    function addDisabilityAssistanceTable(tableData, ws, currentRow) {
        const data = tableData.Leistung

        const consultationHeader1 = ws.rows(currentRow).cells(0);
        const consultationHeader2 = ws.rows(currentRow).cells(1);
        const consultationTypeHeader = ws.rows(currentRow + 1).cells(0);
        currentRow += 2;

        consultationHeader1.value = "Beratung";
        consultationHeader2.value = "Anzahl";
        consultationTypeHeader.value = "Personenbezogen für Menschen mit Behinderung";

        consultationHeader1.cellFormat.font.bold = true;
        consultationHeader2.cellFormat.font.bold = true;
        consultationTypeHeader.cellFormat.font.bold = true;
        consultationTypeHeader.cellFormat.font.italic = true;
        consultationHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        consultationHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

        const consultationSumCell = ws.rows(currentRow).cells(0);
        const consultationSumValue = ws.rows(currentRow).cells(1);

        const consultationSum = data.consultation.find((element) => element._id === "consultationSum");
        const consultationAnonymousContact = data.consultation.find((element) => element._id === "consultationAnonymousContact");
        const anonymousConsultationSum = data.consultation.find((element) => element._id === "anonymousConsultationSum");
        consultationSumCell.value = "Beratungen gesamt";
        consultationSumValue.value = consultationSum ? consultationSum.count : 0;

        consultationSumCell.cellFormat.font.bold = true;
        consultationSumValue.cellFormat.font.bold = true;
        currentRow++
        currentRow = fillDisabilityAssistanceData("differentiationConsultation", data.consultation, ws, currentRow);
        const consultationAnonymousContactCell = ws.rows(currentRow).cells(0);
        const consultationAnonymousContactValue = ws.rows(currentRow).cells(1);

        consultationAnonymousContactCell.value = "   davon anonyme Beratungen";
        consultationAnonymousContactValue.value = consultationAnonymousContact ? consultationAnonymousContact.count : 0;

        const anonymousConsultationCell = ws.rows(currentRow + 1).cells(0);
        anonymousConsultationCell.value = "Nicht personenbezogen"
        anonymousConsultationCell.cellFormat.font.bold = true;
        anonymousConsultationCell.cellFormat.font.italic = true;

        const anonymousConsultationSumCell = ws.rows(currentRow + 2).cells(0);
        const anonymousConsultationSumValue = ws.rows(currentRow + 2).cells(1);

        anonymousConsultationSumCell.value = "Beratung gesamt";
        anonymousConsultationSumValue.value = anonymousConsultationSum ? anonymousConsultationSum.count : 0;
        currentRow += 4;

        const effortConsultationHeader1 = ws.rows(currentRow).cells(0);
        const effortConsultationHeader2 = ws.rows(currentRow).cells(1);

        effortConsultationHeader1.value = "Beratungsaufwand (personenbezogen und nicht personenbezogen)";
        effortConsultationHeader2.value = "Anzahl";

        effortConsultationHeader1.cellFormat.font.bold = true;
        effortConsultationHeader2.cellFormat.font.bold = true;
        effortConsultationHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        effortConsultationHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow++;
        currentRow = fillDisabilityAssistanceData("effortConsultation", data.effortConsultation, ws, currentRow, true);

        const informationHeader1 = ws.rows(currentRow + 1).cells(0);
        const informationHeader2 = ws.rows(currentRow + 1).cells(1);
        /**
         * Bericht BH: Information (personenbezogen und nicht personenbezogen)
         */
        informationHeader1.value = "Information (personenbezogen und nicht personenbezogen)";
        informationHeader2.value = "Anzahl"

        informationHeader1.cellFormat.font.bold = true;
        informationHeader2.cellFormat.font.bold = true;
        informationHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        informationHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow += 2;
        /**
         * Bericht Daten BH: Information (personenbezogen und nicht personenbezogen)
         */
        currentRow = fillDisabilityAssistanceData("differentiationConsultation", data.information, ws, currentRow, true, false, null, null, null, null, data.informationProjectData);
        currentRow++;

        /**
         * Bericht BH: Vermittlung
         */
        const arrangementSum = data.service.find((element) => element._id === "arrangementServiceSum");

        const arrangementHeader1 = ws.rows(currentRow).cells(0);
        const arrangementHeader2 = ws.rows(currentRow).cells(1);
        const arrangementSubHeader = ws.rows(currentRow + 1).cells(0);
        const arrangementSumCell = ws.rows(currentRow + 2).cells(0);
        const arrangementSumValue = ws.rows(currentRow + 2).cells(1);

        arrangementHeader1.value = "Vermittlung";
        arrangementHeader2.value = "Anzahl";
        arrangementSubHeader.value = "Personenbezogen für Menschen mit Behinderung";
        arrangementSumCell.value = "Vermittlungen gesamt";
        arrangementSumValue.value = arrangementSum ? arrangementSum.count : 0;


        arrangementHeader1.cellFormat.font.bold = true;
        arrangementHeader2.cellFormat.font.bold = true;
        arrangementSubHeader.cellFormat.font.bold = true;
        arrangementSubHeader.cellFormat.font.italic = true;
        arrangementSumCell.cellFormat.font.bold = true;
        arrangementSumValue.cellFormat.font.bold = true;
        arrangementHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        arrangementHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow += 3;

        currentRow = fillDisabilityAssistanceData("service", data.service, ws, currentRow, false, false, 3, 5, false, 9);

        const anonymousArrangementSum = data.anonymousService.find((element) => element._id === "anonymousArrangementServiceSum");

        const anonymousArrangementSubHeader = ws.rows(currentRow).cells(0);
        const anonymousArrangementSumCell = ws.rows(currentRow + 1).cells(0);
        const anonymousArrangementSumValue = ws.rows(currentRow + 1).cells(1);

        anonymousArrangementSubHeader.value = "Nicht Personenbezogen";
        anonymousArrangementSumCell.value = "Vermittlungen gesamt";
        anonymousArrangementSumValue.value = anonymousArrangementSum ? anonymousArrangementSum.count : 0;

        anonymousArrangementSubHeader.cellFormat.font.bold = true;
        anonymousArrangementSubHeader.cellFormat.font.italic = true;
        anonymousArrangementSumCell.cellFormat.font.bold = true;
        anonymousArrangementSumValue.cellFormat.font.bold = true;
        currentRow += 2;

        currentRow = fillDisabilityAssistanceData("service", data.anonymousService, ws, currentRow, false, true, 4, 9);
        currentRow++;

        const careHeader1 = ws.rows(currentRow).cells(0);
        const careHeader2 = ws.rows(currentRow).cells(1);
        const careSubHeader = ws.rows(currentRow + 1).cells(0);

        careHeader1.value = "Einzelbetreuung, Unterstützung und Begleitung";
        careHeader2.value = "Anzahl";
        careSubHeader.value = "Personenbezogen für Menschen mit Behinderung";

        careHeader1.cellFormat.font.bold = true;
        careHeader2.cellFormat.font.bold = true;
        careSubHeader.cellFormat.font.bold = true;
        careSubHeader.cellFormat.font.italic = true;
        careHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        careHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

        currentRow += 2;
        currentRow = fillDisabilityAssistanceData("service", data.service, ws, currentRow, false, false, 6, 8);
        currentRow++;

        const otherServiceHeader1 = ws.rows(currentRow).cells(0);
        const otherServiceHeader2 = ws.rows(currentRow).cells(1);
        const otherServiceSubHeader = ws.rows(currentRow + 1).cells(0);

        otherServiceHeader1.value = "Sonstige Leistungen";
        otherServiceHeader2.value = "Anzahl";
        otherServiceSubHeader.value = "Personenbezogen für Menschen mit Behinderung";

        otherServiceHeader1.cellFormat.font.bold = true;
        otherServiceHeader2.cellFormat.font.bold = true;
        otherServiceSubHeader.cellFormat.font.bold = true;
        otherServiceSubHeader.cellFormat.font.italic = true;
        otherServiceHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        otherServiceHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow += 2;

        currentRow = fillDisabilityAssistanceData("service", data.service, ws, currentRow, false, false, 10, 10);

        const anonymousOtherHeader = ws.rows(currentRow).cells(0);
        anonymousOtherHeader.value = "Nicht personenbezogen";
        anonymousOtherHeader.cellFormat.font.bold = true;
        anonymousOtherHeader.cellFormat.font.italic = true;

        currentRow++
        currentRow = fillDisabilityAssistanceData("service", data.anonymousService, ws, currentRow, false, true, 10, 10);
        currentRow++

        const serviceForHeader1 = ws.rows(currentRow).cells(0);
        const serviceForHeader2 = ws.rows(currentRow).cells(1);

        serviceForHeader1.value = "Leistung an";
        serviceForHeader2.value = "%";

        serviceForHeader1.cellFormat.font.bold = true;
        serviceForHeader2.cellFormat.font.bold = true;
        serviceForHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        serviceForHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow++;

        const serviceForSum = data.serviceFor.find((element) => element._id === "serviceForSum");
        currentRow = fillDisabilityAssistanceData("serviceFor", data.serviceFor, ws, currentRow, false, false, null, null, serviceForSum.count);
        currentRow++;

        const eventHeader = ws.rows(currentRow).cells(0);
        const eventSubHeader = ws.rows(currentRow + 1).cells(0);

        eventHeader.value = "4. Veranstaltungsdaten";
        eventSubHeader.value = "Veranstaltungen und Gruppenangebote";

        eventHeader.cellFormat.font.bold = true;
        eventHeader.cellFormat.fill = CellFill.createSolidFill("#bfbfbf");
        eventSubHeader.cellFormat.font.bold = true;
        currentRow += 2;

        currentRow = fillEventData("kindOfEvent", "Veranstaltungsart", data.event.kindOfEvent, ws, currentRow, true);
        currentRow = fillEventData("intervalOfEvent", "Veranstaltungsturnus", data.event.intervalOfEvent, ws, currentRow, true);
        currentRow = fillEventData("offerOnWeekdays", "Angebot an Wochentagen / Wochenenden", data.event.offerOnWeekdays, ws, currentRow, true);
        currentRow = fillEventData("locationOfEvent", "Veranstaltungsort", data.event.locationOfEvent, ws, currentRow, true);

        const outsideEventHeader1 = ws.rows(currentRow).cells(0);
        const outsideEventHeader2 = ws.rows(currentRow).cells(1);
        outsideEventHeader2.value = "%";
        outsideEventHeader2.cellFormat.font.bold = true;
        outsideEventHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        outsideEventHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

        const outsideEventCell = ws.rows(currentRow + 1).cells(0);
        const outsideEventValue = ws.rows(currentRow + 1).cells(1);

        outsideEventValue.cellFormat.alignment = HorizontalCellAlignment.Right;

        outsideEventCell.value = "Der zeitliche Anteil der Veranstaltungen und Treffpunkte,\ndie außerhalb der eigenen Räumlichkeiten stattfinden,\nliegt insgesamt bei (Angabe in Prozent %)";
        outsideEventValue.value = calcOutsideEvents(data.event.locationOfEvent);
        currentRow += 3;

        currentRow = fillEventData("mainContentOfEvent", "Vorrangiger Veranstaltungsschwerpunkt", data.event.mainContentOfEvent, ws, currentRow, true);
        currentRow = fillEventData("orientationParticipants", "Besucher*innenausrichtung", data.event.orientationParticipants, ws, currentRow, true);
        currentRow = fillEventData(null, "Zielgruppe der Veranstaltungen (Mehrfachnennungen möglich) \nMenschen mit...", data.event.disabilities, ws, currentRow, false, true);

        const visitorsHeader1 = ws.rows(currentRow).cells(0);
        const visitorsHeader2 = ws.rows(currentRow).cells(1);

        visitorsHeader1.value = "Besucher*innen";
        visitorsHeader2.value = "Anzahl";

        visitorsHeader1.cellFormat.font.bold = true;
        visitorsHeader2.cellFormat.font.bold = true;

        visitorsHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        visitorsHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

        const visitorSumCell = ws.rows(currentRow + 1).cells(0);
        const visitorSumValue = ws.rows(currentRow + 1).cells(1);

        visitorSumCell.value = "Besucher*innen gesamt";
        visitorSumValue.value = data.event.eventVisitors.sumVisitors;

        const femaleVisitorCell = ws.rows(currentRow + 2).cells(0);
        const femaleVisitorValue = ws.rows(currentRow + 2).cells(1);

        femaleVisitorCell.value = "Davon Frauen (%)";
        const femaleVisitors = data.event.eventVisitors.sumVisitorsFemale / data.event.eventVisitors.sumVisitors * 100
        femaleVisitorValue.value = `${(Math.round(femaleVisitors * 100) / 100).toFixed(2)}%`;

        femaleVisitorValue.cellFormat.alignment = HorizontalCellAlignment.Right;
        currentRow += 4;

        return currentRow
    }

    function fillEventData(key, name, value, ws, currentRow, calcSum = false, noDurationColumn) {
        let data;
        if (noDurationColumn) {
            data = value;
        }
        else {
            data = formEventSelect[key];
        }

        let sum = {
            count: 0,
            sumScheduleAmount: 0,
            sumVisitors: 0,
            sumDuration: 0
        };

        const nameHeader = ws.rows(currentRow).cells(0);
        const eventCountHeader = ws.rows(currentRow).cells(1);
        const appointmentCountHeader = ws.rows(currentRow).cells(2);
        const visitorCountHeader = ws.rows(currentRow).cells(3);

        nameHeader.value = name;
        eventCountHeader.value = "Anzahl Veran-\nstaltungsangebote";
        appointmentCountHeader.value = "Anzahl\nTermine";
        visitorCountHeader.value = "Anzahl\nBesucher*innen";

        nameHeader.cellFormat.font.bold = true;
        eventCountHeader.cellFormat.font.bold = true;
        appointmentCountHeader.cellFormat.font.bold = true;
        visitorCountHeader.cellFormat.font.bold = true;
        nameHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        eventCountHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        appointmentCountHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        visitorCountHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

        if (!noDurationColumn) {
            const durationCountHeader = ws.rows(currentRow).cells(4);
            durationCountHeader.value = "Anzahl Stunden";
            durationCountHeader.cellFormat.font.bold = true;
            durationCountHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        }
        currentRow++

        for (const selection of data) {
            let countObj;
            if (noDurationColumn) {
                countObj = selection;
            }
            else {
                countObj = value.find((obj) => obj._id === selection.id);
            }

            const countValue = countObj?.count ? countObj.count : 0;
            const scheduleValue = countObj?.sumScheduleAmount ? countObj.sumScheduleAmount : 0;
            const visitorValue = countObj?.sumVisitors ? countObj.sumVisitors : 0;

            sum.count += countValue;
            sum.sumScheduleAmount += scheduleValue;
            sum.sumVisitors += visitorValue;

            const keyCell = ws.rows(currentRow).cells(0);
            const countCell = ws.rows(currentRow).cells(1);
            const scheduleCell = ws.rows(currentRow).cells(2);
            const visitorCell = ws.rows(currentRow).cells(3);

            keyCell.value = noDurationColumn ? selection.name : selection.value;
            countCell.value = countValue;
            scheduleCell.value = scheduleValue;
            visitorCell.value = visitorValue;

            if (!noDurationColumn) {
                const durationValue = countObj?.sumDuration ? countObj.sumDuration : 0;
                sum.sumDuration += durationValue;
                const durationCell = ws.rows(currentRow).cells(4);
                durationCell.value = durationValue;
            }

            currentRow++
        }

        if (calcSum) {
            const sumCell = ws.rows(currentRow).cells(0);
            const countCell = ws.rows(currentRow).cells(1);
            const scheduleCell = ws.rows(currentRow).cells(2);
            const visitorCell = ws.rows(currentRow).cells(3);

            sumCell.value = "Gesamt";
            countCell.value = sum.count;
            scheduleCell.value = sum.sumScheduleAmount;
            visitorCell.value = sum.sumVisitors;

            sumCell.cellFormat.font.bold = true;
            countCell.cellFormat.font.bold = true;
            scheduleCell.cellFormat.font.bold = true;
            visitorCell.cellFormat.font.bold = true;

            if (!noDurationColumn) {
                const durationCell = ws.rows(currentRow).cells(4);
                durationCell.value = sum.sumDuration;
                durationCell.cellFormat.font.bold = true;
            }
            currentRow++
        }

        currentRow++;
        return currentRow;
    }

    function calcOutsideEvents(eventData) {
        let notSpecified = 0;
        let outsideEvents = 0;
        let sum = 0;
        for (const event of eventData) {
            if (event._id === 1) {
                notSpecified = event.sumScheduleAmount ? event.sumScheduleAmount : 0;
            }
            if (event._id === 3) {
                outsideEvents = event.sumScheduleAmount ? event.sumScheduleAmount : 0;
            }
            if (event.count) {
                sum += event.sumScheduleAmount;
            }
        }

        let outsideEventPercentage;
        if (sum - notSpecified !== 0) {
            outsideEventPercentage = outsideEvents / (sum - notSpecified) * 100;
        }
        else {
            outsideEventPercentage = 0;
        }
        return outsideEventPercentage.toFixed(2) + "%";
    }

    /**
     * Bericht Daten BH: Information (personenbezogen und nicht personenbezogen)
     * @param {*} key 
     * @param {*} value 
     * @param {*} ws 
     * @param {*} currentRow 
     * @param {*} calcSum 
     * @param {*} anonymous 
     * @param {*} start 
     * @param {*} end 
     * @param {*} percentageOf 
     * @param {*} extra 
     * @param {*} info 
     * 
     * @returns 
     */
    function fillDisabilityAssistanceData(key, value, ws, currentRow, calcSum = false, anonymous = false, start, end, percentageOf, extra, info = 0) {
        const selectConfig = anonymous ? tableSelectNonPersonalConfig.services[key] : tableSelectConfig.services[key];
        let sum = 0;
        for (const selection of selectConfig) {
            if ((start && selection.id < start) || (end && selection.id > end)) {
                if (extra) {
                    if (selection.id !== extra) {
                        continue;
                    }
                }
                else {
                    continue;
                }
            }
            const countObj = value.find((obj) => obj._id === selection.id);
            let cellValue;
            if (percentageOf) {
                cellValue = countObj ? ((countObj.count / percentageOf * 100).toFixed(2)) : 0;
                cellValue = cellValue + "%";
            }
            else {
                cellValue = countObj ? countObj.count : 0;
                sum += cellValue;
            }

            const keyCell = ws.rows(currentRow).cells(0);
            const valueCell = ws.rows(currentRow).cells(1);

            keyCell.value = selection.value;
            valueCell.value = cellValue;

            if (percentageOf) {
                valueCell.cellFormat.alignment = HorizontalCellAlignment.Right;
            }

            currentRow++
        }

        if (calcSum) {
            const sumCell = ws.rows(currentRow).cells(0);
            const sumValue = ws.rows(currentRow).cells(1);

            sumCell.value = "Gesamt";
            sumValue.value = sum;

            sumCell.cellFormat.font.bold = true;
            sumValue.cellFormat.font.bold = true;
            currentRow++
        }

        if (key === 'differentiationConsultation' && info) {
            const keyCell = ws.rows(currentRow).cells(0);
            const valueCell = ws.rows(currentRow).cells(1);

            keyCell.value = "Informationen aus summarischer Erfassung";
            valueCell.value = info;
            currentRow++;
        }

        return currentRow;
    }

    function addProjectData(currentRow, workbook) {
        const project = user.userProject;
        const ws = workbook.worksheets("SSB-TeilC");

        const header1 = ws.rows(currentRow).cells(0);
        const header2 = ws.rows(currentRow).cells(1);

        header1.value = "Angaben zu Mitarbeiter*innen";
        header2.value = "Anzahl";

        header1.cellFormat.font.bold = true;
        header2.cellFormat.font.bold = true;
        header1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        header2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");

        const keyCell1 = ws.rows(currentRow + 1).cells(0);
        const valueCell1 = ws.rows(currentRow + 1).cells(1);
        const keyCell2 = ws.rows(currentRow + 2).cells(0);
        const valueCell2 = ws.rows(currentRow + 2).cells(1);
        const keyCell3 = ws.rows(currentRow + 3).cells(0);
        const valueCell3 = ws.rows(currentRow + 3).cells(1);
        const keyCell4 = ws.rows(currentRow + 4).cells(0);
        const valueCell4 = ws.rows(currentRow + 4).cells(1);
        const keyCell5 = ws.rows(currentRow + 5).cells(0);
        const valueCell5 = ws.rows(currentRow + 5).cells(1);
        const keyCell6 = ws.rows(currentRow + 6).cells(0);
        const valueCell6 = ws.rows(currentRow + 6).cells(1);
        const keyCell7 = ws.rows(currentRow + 7).cells(0);
        const valueCell7 = ws.rows(currentRow + 7).cells(1);

        keyCell1.value = "Ehrenamtliche Mitarbeiter*innen";
        valueCell1.value = project.volunteerWorkers ? project.volunteerWorkers : 0;
        keyCell2.value = "   davon weiblich";
        valueCell2.value = project.volunteerWorkersF ? project.volunteerWorkersF : 0;
        keyCell3.value = "   davon neu hinzugekommen";
        valueCell3.value = project.volunteerWorkersNew ? project.volunteerWorkersNew : 0;
        keyCell4.value = "Sonstige";
        valueCell4.value = project.otherWorkers ? project.otherWorkers : 0;
        keyCell5.value = "   davon weiblich";
        valueCell5.value = project.otherWorkersF ? project.otherWorkersF : 0;
        keyCell6.value = "Keine Angaben";
        valueCell6.value = project.workersNoInfo ? project.workersNoInfo : 0;
        keyCell7.value = "   davon weiblich";
        valueCell7.value = project.workersNoInfoF ? project.workersNoInfoF : 0;

        currentRow += 8;
        return [workbook, currentRow];
    }

    function addDisabilityAssistanceProjectData(currentRow, workbook) {
        const project = user.userProject;
        const ws = workbook.worksheets("SSB-TeilC");

        const employeesHeader1 = ws.rows(currentRow).cells(0);
        const employeesHeader2 = ws.rows(currentRow).cells(1);

        employeesHeader1.value = "4. Anleiter, Assistenten und Moderatoren, Ehrenamtliche (Stichtag 30.06)";
        employeesHeader2.value = "Anzahl";

        employeesHeader1.cellFormat.font.bold = true;
        employeesHeader2.cellFormat.font.bold = true;
        employeesHeader1.cellFormat.fill = CellFill.createSolidFill("#bfbfbf");
        employeesHeader2.cellFormat.fill = CellFill.createSolidFill("#bfbfbf");

        const employeesSumCell = ws.rows(currentRow + 1).cells(0);
        const employeesSumValue = ws.rows(currentRow + 1).cells(1);

        employeesSumCell.value = "Mitarbeiter*innen gesamt";
        employeesSumValue.value = project.numberOfEmployees;

        const volunteerWorkersCell = ws.rows(currentRow + 2).cells(0);
        const volunteerWorkersValue = ws.rows(currentRow + 2).cells(1);

        volunteerWorkersCell.value = "Ehrenamt gesamt";
        volunteerWorkersValue.value = project.volunteerWorkers;

        const withExperienceCell = ws.rows(currentRow + 3).cells(0);
        const withExperienceValue = ws.rows(currentRow + 3).cells(1);

        withExperienceCell.value = "   Davon mit Vorkenntnissen / Vorerfahrungen";
        withExperienceValue.value = project.employeePreviousExperience;

        const femaleEmployeesCell = ws.rows(currentRow + 4).cells(0);
        const femaleEmployeesValue = ws.rows(currentRow + 4).cells(1);

        femaleEmployeesCell.value = "   Davon Frauen";
        femaleEmployeesValue.value = project.femaleEmployees;
        currentRow += 6;

        const offerType = project.projectOffer.projectOfferType?.toLowerCase();

        if (offerType.includes("freizeit")) {
            currentRow = fillLeisuretimeProjectData(currentRow, ws);
        }
        else if (offerType.includes("wohnvermittlung")) {
            currentRow = fillHousingProjectData(currentRow, ws)
        }

        return [workbook, currentRow]
    }

    function fillHousingProjectData(currentRow, ws) {
        const HousingBrokerageConfig = formConfig.projectHousingBrokerage.tabs[1].containers;
        const project = user.userProject;

        const part2Header = ws.rows(currentRow).cells(0);
        const cooperationHeader1 = ws.rows(currentRow + 1).cells(0);
        const cooperationHeader2 = ws.rows(currentRow + 1).cells(1);

        part2Header.value = "Teil II";
        cooperationHeader1.value = "Kooperation, Vernetzung, Öffentlichkeitsarbeit";
        cooperationHeader2.value = "Anzahl";

        part2Header.cellFormat.font.bold = true;
        cooperationHeader1.cellFormat.font.bold = true;
        cooperationHeader2.cellFormat.font.bold = true;
        part2Header.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        cooperationHeader1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        cooperationHeader2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow += 2

        for (const container of HousingBrokerageConfig) {
            for (const element of container.elements) {
                const cell = ws.rows(currentRow).cells(0);
                const value = ws.rows(currentRow).cells(1);

                cell.value = element[0].name;
                value.value = project[element[1].value] ? project[element[1].value] : "/";
                currentRow++;
            }
            currentRow++;
        }
        return currentRow;
    }

    function fillLeisuretimeProjectData(currentRow, ws) {
        const leisureTimeFormConfig = formConfig.projectLeisureActivities.tabs[1].containers
        const part2Header = ws.rows(currentRow).cells(0);
        const qualityHeader = ws.rows(currentRow + 1).cells(0);

        part2Header.value = "Teil II";
        qualityHeader.value = "1. Qualität";

        part2Header.cellFormat.font.bold = true;
        qualityHeader.cellFormat.font.bold = true;
        part2Header.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        qualityHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow += 2;

        currentRow = fillLeisureDataFromConfig(leisureTimeFormConfig[0].elements, currentRow, ws);

        const holderDataHeader = ws.rows(currentRow).cells(0);
        holderDataHeader.value = "2. Trägerangaben aufgrund von Teilnehmer*innenrückmeldungen";
        holderDataHeader.cellFormat.font.bold = true;
        holderDataHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        currentRow++;

        currentRow = fillLeisureDataFromConfig(leisureTimeFormConfig[1].elements, currentRow, ws);
        return currentRow
    }

    function fillLeisureDataFromConfig(container, currentRow, ws) {
        const duplicateChecker = {};
        const project = user.userProject;

        let nextFieldValue = false;
        for (const element of container) {
            if (duplicateChecker[element.value]) {
                continue;
            }

            if (element.inputType === inputTypes.PARAGRAPH) {
                const paragraphCell = ws.rows(currentRow).cells(0);
                paragraphCell.value = element.name.replace(/((?:.*?\s){8}.*?)\s/g, '$1\n');
                nextFieldValue = true;
                continue;
            }
            if (nextFieldValue) {
                const valueCell = ws.rows(currentRow).cells(1);
                valueCell.value = project[element.value] ? project[element.value] : "/";
                nextFieldValue = false;
                currentRow++;
            }

            let extraField;
            let radioSelected = false;
            if (element.inputType === inputTypes.RADIO) {
                extraField = container.find((obj) => obj.value === element.value && obj.inputType !== inputTypes.RADIO);
                const radioHeader = ws.rows(currentRow).cells(0);

                radioHeader.value = element.name.replace(/((?:.*?\s){8}.*?)\s/g, '$1\n')
                currentRow++;

                for (const radio of element.radios) {
                    const radioCell = ws.rows(currentRow).cells(0);
                    const radioValue = ws.rows(currentRow).cells(1);

                    radioCell.value = "   " + radio.name;
                    radioValue.value = project[element.value] === radio.value ? "Ja" : "Nein";
                    radioSelected = radioSelected || project[element.value] === radio.value;
                    currentRow++;
                }
                duplicateChecker[element.value] = true;
            }

            if (element.inputType === inputTypes.MULTILINE) {
                const multilineCell = ws.rows(currentRow).cells(0);
                const multilineValue = ws.rows(currentRow).cells(1);

                multilineCell.value = element.name;
                multilineValue.value = project[element.value] ? project[element.value].replace(/((?:.*?\s){8}.*?)\s/g, '$1\n') : "/";
                currentRow++;
            }

            if (extraField) {
                const extraFieldCell = ws.rows(currentRow).cells(0);
                const extraFieldValue = ws.rows(currentRow).cells(1);

                extraFieldCell.value = "   " + extraField.name;
                extraFieldValue.value = !radioSelected && project[element.value] ? project[element.value].replace(/((?:.*?\s){5}.*?)\s/g, '$1\n') : "/";
                currentRow++
            }
            currentRow++;
        }

        return currentRow;
    }

    function addLegalRepresentation(currentRow, workbook) {
        const ws = workbook.worksheets("SSB-TeilC");
        const textCell = ws.rows(currentRow).cells(0);
        const locationDateCell = ws.mergedCellsRegions().add(currentRow + 3, 0, currentRow + 3, 1);
        const signCell = ws.mergedCellsRegions().add(currentRow + 6, 0, currentRow + 6, 1);
        currentRow += 7;

        textCell.value = "Wir erklären hiermit rechtsverbindlich, dass der am 31.12." + reportYear + " per\nFAZIT-Online-Verfahren versandte Strukturierte Sachbericht mit dem\nhier beigelegten Ausdruck identisch ist."

        locationDateCell.value = "Ort und Datum";

        signCell.value = "Rechtsverbindliche Unterschrift/en, Stempel"

        return [workbook, currentRow];

    }

    /**
     * adds tab "Soziodemographische Daten" to Excel (Kreuztabelle) - only for Beratungsstellen
     * @param {} workbook 
     * @param {*} dates 
     * @returns 
     */
    async function addOverview(workbook, dates) {
        const ws = workbook.worksheets().add("Soziodemografische Daten");
        let currentRow = 0;

        ws.columns(0).width = 12000;
        ws.columns(9).width = 3000;
        ws.columns(10).width = 3000;
        ws.columns(11).width = 3000;
        ws.columns(12).width = 3000;

        const header1 = ws.rows(currentRow).cells(0);
        const header2 = ws.rows(currentRow + 1).cells(0);
        const dateState = ws.rows(currentRow + 1).cells(1);
        const header3 = ws.rows(currentRow + 2).cells(0);
        const clientSum = ws.rows(currentRow + 2).cells(8);

        header1.value = "Beschluss SSB, Teil C";
        header2.value = "für Beratungsstellen und Freizeitanbieter";
        dateState.value = `Stand: ${dayjs(form.exportFrom).format('DD.MM.YYYY')} - ${dayjs(form.exportTo).format('DD.MM.YYYY')}`;
        header3.value = `Soziodemographische Daten der Zielgruppe (${reportYear})`;

        header1.cellFormat.font.bold = true;
        header3.cellFormat.font.bold = true;
        currentRow += 4;

        const table1Header = ["Vorrangige Behinderung", "Menschen mit körperlicher Behinderung", "Menschen mit geistiger Behinderung", "Menschen mit seelischer Behinderung"]
        currentRow = addTableHeader(table1Header, currentRow, ws);

        await axios.post(`${apiConfig.rest}/user/data/overview`, { dates }, config)
            .then((res) => {
                clientSum.value = `Anzahl Klient*innen insgesamt: ${res.data.clientSum}`;

                const disabilities1 = [3, 2, 5];
                currentRow = fillTable(res.data, disabilities1, currentRow, ws);

                const table2Header = ["Vorrangige Behinderung", "Menschen mit Autismus", "Menschen mit Sinnesbehinderung", "Keine Angabe"]
                currentRow = addTableHeader(table2Header, currentRow, ws, true);
                const disabilities2 = [6, 4];
                currentRow = fillTable(res.data, disabilities2, currentRow, ws, true);
            });

        const dExplanation = ws.rows(currentRow).cells(0);
        dExplanation.value = "d = divers";
        dExplanation.cellFormat.fill = CellFill.createSolidFill("#fffeca");
        currentRow += 2;

        const legalText1 = ws.rows(currentRow).cells(0);
        const legalDate1 = ws.rows(currentRow).cells(1);
        const legalText2 = ws.rows(currentRow).cells(2);
        const legalText3 = ws.rows(currentRow+2).cells(0);
        const legalDate2 = ws.rows(currentRow+2).cells(1);
        const legalText4 = ws.rows(currentRow+4).cells(0);
        const legalText5 = ws.rows(currentRow+4).cells(2);

        legalText1.value = "Wir erklären hiermit rechtsverbindlich, das der am";
        legalDate1.value = "[Datum]";
        legalText2.value = "per Mail versendete Strukturierte Sachbericht mit dem hier beigelegten Ausdruck identisch ist.";
        legalText3.value = "Ort und Datum: Berlin, den";
        legalDate2.value = "[Datum]";
        legalText4.value = "rechtsverbindliche Unterschrift/en, Stempel";
        legalText5.value = "Vielen Dank für Ihre Mitarbeit!";

        legalDate1.cellFormat.fill = CellFill.createSolidFill("#fffeca");
        legalDate2.cellFormat.fill = CellFill.createSolidFill("#fffeca");

        return workbook;
    }

    function fillTable(resData, disabilities, currentRow, ws, noInfo = false) {
        const data = resData.tableData;
        const ages = ["age17", "age27", "age64", "age65"];
        const noInfoData = ["ageSum", "genderSum", "disabilitySum"];
        const genders = [2, 3, 4, "total"];

        const age17 = ws.rows(currentRow).cells(0);
        const age27 = ws.rows(currentRow + 1).cells(0);
        const age64 = ws.rows(currentRow + 2).cells(0);
        const age65 = ws.rows(currentRow + 3).cells(0);
        const sum = ws.rows(currentRow + 4).cells(0);

        age17.value = "Bis 17 Jahre";
        age27.value = "18 - 27 Jahre";
        age64.value = "28 - 64 Jahre";
        age65.value = "65 Jahre und älter";
        sum.value = "Gesamt";

        age17.cellFormat.fill = CellFill.createSolidFill("#fffeca");
        age17.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        age17.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        age17.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        age17.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        age27.cellFormat.fill = CellFill.createSolidFill("#fffeca");
        age27.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        age27.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        age27.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        age27.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        age64.cellFormat.fill = CellFill.createSolidFill("#fffeca");
        age64.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        age64.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        age64.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        age64.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        age65.cellFormat.fill = CellFill.createSolidFill("#fffeca");
        age65.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        age65.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        age65.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        age65.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        sum.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        sum.cellFormat.font.bold = true;
        sum.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        sum.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        sum.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        sum.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;

        let currentColumn = 1;
        for (const disability of disabilities) {
            const dataSet = data.find(obj => obj._id === disability);
            let epsilonSum = 0;

            for (let i = 0; i < ages.length; i++) {
                for (let j = 0; j < genders.length; j++) {
                    const cell = ws.rows(currentRow + i).cells(currentColumn + j);
                    cell.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                    cell.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                    cell.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                    cell.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;

                    if (!dataSet) {
                        cell.value = 0;
                    }
                    else {
                        if (genders[j] === "total") {
                            const cellValue = dataSet[ages[i]] ? dataSet[ages[i]].totalObjects : 0
                            epsilonSum += cellValue;
                            cell.value = cellValue;
                        }
                        else {
                            let cellValue;
                            if (dataSet[ages[i]]) {
                                cellValue = dataSet[ages[i]].genders.find(obj => obj.gender === genders[j]);
                            }

                            cell.value = cellValue ? cellValue.count : 0;
                        }
                    }
                }
            }

            for (let j = 0; j < genders.length; j++) {
                const cell = ws.rows(currentRow + 4).cells(currentColumn + j);
                cell.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                cell.cellFormat.font.bold = true;
                cell.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                cell.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                cell.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                cell.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;

                if (!dataSet) {
                    cell.value = 0;
                }
                else {
                    if (genders[j] === "total") {
                        cell.value = epsilonSum;
                    }
                    else {
                        let cellValue;
                        if (dataSet.genderCounts) {
                            cellValue = dataSet.genderCounts.find(obj => obj.gender === genders[j]);
                        }

                        cell.value = cellValue ? cellValue.count : 0;
                    }
                }
            }

            currentColumn += 4;
        }

        if (noInfo) {
            for (let i = 0; i < ages.length; i++) {
                for (let j = 0; j < 3; j++) {
                    const cell = ws.rows(currentRow + i).cells(currentColumn + j);
                    cell.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                    cell.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                    cell.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                    cell.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;

                    cell.value = "--";
                }
            }

            for(let i = 0; i < 3; i++) {
                const cell = ws.rows(currentRow + 4).cells(currentColumn + i);
                cell.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                cell.cellFormat.font.bold = true;
                cell.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                cell.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                cell.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                cell.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;

                const cellValue = resData[noInfoData[i]];
                cell.value = cellValue ? cellValue : 0;
            }
        }

        return currentRow += ages.length + 2;
    }

    function addTableHeader(header, currentRow, ws, noInfo = false) {
        const header1 = ws.mergedCellsRegions().add(currentRow, 0, currentRow + 1, 0);
        const header2 = ws.mergedCellsRegions().add(currentRow, 1, currentRow + 1, 4);
        const header3 = ws.mergedCellsRegions().add(currentRow, 5, currentRow + 1, 8);
        const header4 = ws.mergedCellsRegions().add(currentRow, 9, currentRow + 1, noInfo ? 11 : 12);

        header1.value = header[0];
        header2.value = header[1];
        header3.value = header[2];
        header4.value = header[3];

        header1.cellFormat.font.bold = true;
        header1.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        header1.cellFormat.verticalAlignment = VerticalAlignment.Top;
        header1.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        header1.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        header1.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        header1.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        header2.cellFormat.font.bold = true;
        header2.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        header2.cellFormat.verticalAlignment = VerticalAlignment.Top;
        header2.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        header2.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        header2.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        header2.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        header3.cellFormat.font.bold = true;
        header3.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        header3.cellFormat.verticalAlignment = VerticalAlignment.Top;
        header3.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        header3.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        header3.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        header3.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        header4.cellFormat.font.bold = true;
        header4.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
        header4.cellFormat.verticalAlignment = VerticalAlignment.Top;
        header4.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
        header4.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
        header4.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
        header4.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
        currentRow += 2

        let currentColumn = 0;
        for (let i = 0; i < header.length; i++) {
            if (i === 0) {
                const genderHeader = ws.rows(currentRow).cells(currentColumn);
                genderHeader.value = "Geschlecht";
                genderHeader.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                genderHeader.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                genderHeader.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                genderHeader.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                genderHeader.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
                currentColumn++;
            }
            else {
                const male = ws.rows(currentRow).cells(currentColumn);
                const female = ws.rows(currentRow).cells(currentColumn + 1);
                const diverse = ws.rows(currentRow).cells(currentColumn + 2);

                male.value = noInfo && i === header.length - 1 ? "Alter" : "m";
                female.value = noInfo && i === header.length - 1 ? "Geschlecht" : "w";
                diverse.value = noInfo && i === header.length - 1 ? "Behinderung" : "d";

                male.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                male.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                male.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                male.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                male.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
                female.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                female.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                female.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                female.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                female.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
                diverse.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                diverse.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                diverse.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                diverse.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                diverse.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;

                if (!noInfo || (noInfo && i !== header.length - 1)) {
                    const sum = ws.rows(currentRow).cells(currentColumn + 3);
                    sum.value = "∑";
                    sum.cellFormat.fill = CellFill.createSolidFill("#d9d9d9");
                    sum.cellFormat.topBorderStyle = CellBorderLineStyle.Thin;
                    sum.cellFormat.bottomBorderStyle = CellBorderLineStyle.Thin;
                    sum.cellFormat.leftBorderStyle = CellBorderLineStyle.Thin;
                    sum.cellFormat.rightBorderStyle = CellBorderLineStyle.Thin;
                }

                currentColumn += 4;
            }
        }

        currentRow++
        return currentRow;
    }
/**
 * Event Excel export: exports SSC Bericht
 * @param {*} e 
 */
    async function handleClick(e) {
        let currentRow = 0;
        let workbook;
        let fields;
        //date from and date to are neccessary
        if (form.exportFrom && form.exportTo) {
            //at least one client with services is nesseccary
            if (clients?.length > 0) {
                const dates = { ...form, reportYear: reportYear };
                [workbook, currentRow] = createWorkbook();
                dates.exportFrom = dates.exportFrom.setTime(dates.exportFrom.getTime() + (1 * 60 * 60 * 1000));
                dates.exportTo = dates.exportTo.setTime(dates.exportTo.getTime() + (1 * 60 * 60 * 1000));
                [workbook, currentRow] = await addAgeToWorkbook(dates, currentRow, workbook);
                [workbook, currentRow, fields] = await addSociodemographicData(dates, currentRow, workbook);
                [workbook, currentRow] = addTableData(currentRow, workbook, fields);
                // project of WLH
                if (user.userProject.projectType.id === 1) {
                    [workbook, currentRow] = addProjectData(currentRow, workbook);
                } // project of type BH
                else if (user.userProject.projectType.id === 2) {
                    [workbook, currentRow] = addDisabilityAssistanceProjectData(currentRow, workbook);
                    [workbook, currentRow] = addLegalRepresentation(currentRow, workbook);
                    //@todo replace with constant instead of number
                    if (user.userProject.projectOffer.projectOfferTypeID === 10) {
                        workbook = await addOverview(workbook, dates);
                    }
                }
                workbookSave(workbook);
            }
            else {
                setError("Ein Export ohne Klient*innendaten mit Leistungen ist nicht möglich!");
            }
        }
        else {
            setError("Geben sie bitte ein Datum in beide Felder ein!");
        }
    }

    return (
        <div id="excel-export">
            <Headline name="Strukturierter Sachbericht Teil C" />
            <div className="content">
                <div id="excel-form-content">
                    <Form>
                        <Datepicker label="Von" value={dayjs(form.exportFrom)} selected={dayjs(form.exportFrom)} minDate={minDate} maxDate={maxDate} name="exportFrom" onChange={handleChange} />
                        <p>-</p>
                        <Datepicker label="Bis" value={dayjs(form.exportTo)} selected={dayjs(form.exportTo)} minDate={minDate} maxDate={maxDate} name="exportTo" onChange={handleChange} />
                    </Form>

                    <Button sx={{ textTransform: 'none', borderRadius: '0', height: '3rem', marginTop: '0.4rem'}} className="dwbo-btn-purple" onClick={handleClick} variant="contained">Excel exportieren</Button>
                </div>
                {error &&
                    <Alert variant="outlined" severity="error">{error}</Alert>
                }
                <DataTable clients={clients} />
            </div>
        </div>
    );
}

const DataTable = ({ clients }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Klient*innen',
                disableSortBy: true,
                columns: [
                    {
                        Header: 'Nachname',
                        accessor: 'lastName'
                    },
                    {
                        Header: 'Vorname',
                        accessor: 'firstName'
                    },
                    {
                        Header: 'Erstellt am',
                        accessor: 'createdAt',
                        Cell: ({ row }) => (
                            <span>{moment(row.original.createdAt).format('DD/MM/YYYY')}</span>
                        )
                    },
                ],
            }
        ],
        []
    );

    if (clients) {
        if (clients.length > 0) {
            return (
                <Table columns={columns} data={clients} initialSortColumn={"lastName"} />
            )
        }
        else {
            return (
                <p style={{ marginTop: '1rem' }}>Keine Klient*innen gefunden</p>
            )
        }
    }
    else {
        return;
    }
}

export default ExcelExport;