import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    Col,
    Container,
    Row
} from "react-bootstrap";
import * as Icons from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//created imports
import Table from '../../components/table/Table';
import apiConfig from '../../config/config';
import Headline from '../../components/Headline';
import { setSelectedClient } from '../../features/client/clientSlice';
import { getToken, getUser } from '../../features/user/userSlice';

/**
 * @author Elian Plaschnick
 */
const ClientView = () => {
    const [allClients, setAllClients] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userToken = useSelector(getToken);
    const user = useSelector(getUser);

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        await axios.get(`${apiConfig.rest}/user/data/client`, config).then((response) => {
            setAllClients(response.data);
        }).catch((err) => {
        });
    }

    function arrayLengthSort(rowA, rowB, id, desc) {
        if (rowA.values[id].length > rowB.values[id].length) return 1;
        if (rowB.values[id].length > rowA.values[id].length) return -1;
        return 0;
    }

    const onEdit = (element) => {
        dispatch(setSelectedClient(element));
        navigate('/user/dataCollection');
    }

    const columns = React.useMemo(
        () => {
            return user.userProject.projectType.id === 3 ? [
                {
                    Header: 'Klient*innen',
                    disableSortBy: true,
                    columns: [
                        {
                            Header: '_id',
                            accessor: '_id'
                        },
                        {
                            Header: 'Nachname',
                            accessor: 'lastName'
                        },
                        {
                            Header: 'Vorname',
                            accessor: 'firstName'
                        },
                        {
                            Header: 'Str. + Hausnr.',
                            accessor: 'addressStreet'
                        },
                        {
                            Header: 'Ort',
                            accessor: 'addressCity'
                        },
                        {
                            Header: 'Bearbeiten',
                            accessor: 'actions',
                            disableSortBy: true,
                            Cell: ({ row }) => (
                                <Button
                                    variant="info"
                                    title="Bearbeiten"
                                    className={`btn-primary btn-sm`}
                                    onClick={() => onEdit(row.original)}>
                                    <Icons.FaPencilAlt />
                                </Button>
                            )
                        }
                    ],
                }
            ] : [
                {
                    Header: 'Klient*innen',
                    disableSortBy: true,
                    columns: [
                        {
                            Header: '_id',
                            accessor: '_id'
                        },
                        {
                            Header: 'Nachname',
                            accessor: 'lastName'
                        },
                        {
                            Header: 'Vorname',
                            accessor: 'firstName'
                        },
                        {
                            Header: 'Str. + Hausnr.',
                            accessor: 'addressStreet'
                        },
                        {
                            Header: 'Ort',
                            accessor: 'addressCity'
                        },
                        {
                            Header: "Projektstandort",
                            accessor: 'projectLocation',
                            headerClassName: "align-left" /*,
                        sortType: arrayLengthSort,
                        Cell: ({ row }) => {
                            if (row.original.problemAreas) {
                                return (row.original.problemAreas.length);
                            }

                            return;
                        }*/
                        },
                        {
                            Header: 'Bearbeiten',
                            accessor: 'actions',
                            disableSortBy: true,
                            Cell: ({ row }) => (
                                <Button
                                    variant="info"
                                    title="Bearbeiten"
                                    className={`btn-primary btn-sm`}
                                    onClick={() => onEdit(row.original)}>
                                    <Icons.FaPencilAlt />
                                </Button>
                            )
                        }
                    ],
                }
            ]
        },
        []
    );

    return (
        <div>
            <Headline name="Klient*innenübersicht" />

            <Container className="list-container">
                <Row>
                    <Col id='client-view'>
                        <Table columns={columns} data={allClients} initialSortColumn="lastName" paginationSize="200" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ClientView;