import { width } from "@mui/system";
import React, { useEffect, useState } from "react";
import Headline from "../../components/Headline";
import { useDispatch, useSelector } from "react-redux";
import { getFormConfig, getToken, getUser } from "../../features/user/userSlice";
import * as Icons from "react-icons/fa";
import { getUserDetails } from "../../features/user/userActions";
import ProjectLocationTable from "./projectData/ProjectLocationTable";
import BasicTable from "./projectData/BasicTable";
import ProjectAddressData from "./projectData/ProjectAddressData";
import { responseNotifyHandling } from "../../components/Error";
import { updateProject, updateUserProject } from "../../features/admin/projectManagementActions";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Button, Tab, Tabs } from "react-bootstrap";
import { TextField } from "@mui/material";
import GenericForm from "../../components/GenericForm";
import { getInitDatabaseObject } from "../../config/configEnums";
import { FormState, setFormState } from "../../features/form/formSlice";

/* const initCurrentProject = {
    projectID: "",
    projectName: "",
    userDescription: "",
    projectNumber: "",
    isActive: true,
    projectLocation: [],
    projectEmployees: {
        volunteerWorkers: 0,
        volunteerWorkersF: 0,
        volunteerWorkersNew: 0,
        otherWorkers: 0,
        otherWorkersF: 0,
        workersNoInfo: 0,
        workersNoInfoF: 0
    },
    projectAddress: {
        street: "",
        postalCode: "",
        city: ""
    },
    projectHolderAddress: {
        street: "",
        postalCode: "",
        city: ""
    }
}; */

let initCurrentForm;

/**
 * @author Elian Plaschnick
 */
function FormPage() {
    const fullFormConfig = useSelector(getFormConfig);
    const userInfo = useSelector(state => state.user.userInfo);

    const findFormConfig = () => {
        const urlSplit = window.location.href.split('/');
        let configName = urlSplit[urlSplit.length - 1];
        const offerType = userInfo.userProject.projectOffer.projectOfferType?.toLowerCase();

        if (userInfo.userProject.projectType.id === 2 && configName === "project" && offerType) {
            if (offerType.includes("freizeit")) {
                configName = configName + "LeisureActivities";
            }
            else if (offerType.includes("wohnvermittlung")) {
                configName = configName + "HousingBrokerage";
            }
        }

        return fullFormConfig[configName];
    }

    const userToken = useSelector(getToken);
    const dispatch = useDispatch();
    const userProject = userInfo.userProject;

    const [formConfig, setFormConfig] = useState(findFormConfig())

    const [initCurrentForm, setInitCurrentForm] = useState(getInitDatabaseObject(formConfig))
    const [form, setForm] = useState({ ...initCurrentForm, ...userInfo.userProject });
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = () => {
        const newProject = { ...form }
        newProject.projectLocation = newProject.projectLocation.filter(element => element !== "");
        updateUserProject(newProject, userToken)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getUserDetails());
                    dispatch(setFormState(FormState.UNCHANGED));
                    setShowModal(false);
                    responseNotifyHandling(res, res.status, `Das Projekt [${userInfo.userProject.projectName}] wurde aktualisiert`);
                }
            })
            .catch((err) => {
                responseNotifyHandling(err.response, err.response.status, err.response.message)
            })
    }

    return (
        <div className="project-data-root">
            <ConfirmationDialog
                showModal={showModal}
                confirmModal={handleSubmit}
                hideModal={() => setShowModal(false)}
                message={"Soll ihr Projekt aktualisiert werden?"}
                title={"Projekt aktualisieren"}
                btnCancelTxt={"Abbrechen"}
                btnSubmitTxt={"Speichern"}
                btnSubmitColor={"success"}
            />
            <Headline name={`[${userProject.projectID}] ${userProject.projectName} - ${userProject.projectNumber}`} />
            <div>
                <Button
                    variant="success"
                    className="save mb-4"
                    onClick={() => setShowModal(true)}>
                    <Icons.FaSave style={{ transform: "translate(-0.25rem, -0.09rem)" }} />
                    Speichern
                </Button>
            </div>

            {formConfig.tabs ? (
                <Tabs
                    defaultActiveKey={formConfig.tabs[0].key}
                    className="mb-3"
                    justify
                >
                    {formConfig.tabs.map((tab) => (
                        <Tab key={tab.key} eventKey={tab.key} title={tab.name}>
                            <GenericForm
                                formConfig={tab}
                                databaseObject={form}
                                setDatabaseObject={setForm}
                            />
                        </Tab>
                    ))}
                </Tabs>
            ) : (
                <GenericForm
                    formConfig={formConfig}
                    databaseObject={form}
                    setDatabaseObject={setForm}
                />
            )}

        </div>
    )
}

export default FormPage;