import React, { useState } from "react";
import { TextField, Button, Alert } from "@mui/material";
import Headline from "../components/Headline";
import axios from "axios";
import apiConfig from "../config/config";

/**
 * @author Elian Plaschnick
 */
const ForgotPassword = () => {
    const [form, setForm] = useState({});
    const [error, setError] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const newForm = { ...form };
        newForm[e.target.name] = e.target.value;
        setForm(newForm);

        if(error) {
            setError("");
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!form.userMail) {
            setError("Keine E-Mail angegeben!");
        }
        else {
            setLoading(true);
            axios.post(`${apiConfig.rest}/user/forgot-password`, { userMail: form.userMail })
                .then((res) => {
                    if(res.status === 200) {
                        setShowSuccess(true);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    if(err.response.data) {
                        setError(err.response.data.message);
                    }
                    else {
                        console.log(err);
                    }
                })
        }
    }

    return (
        <div className="forgot-password">
            <Headline name="Passwort vergessen" />
            {showSuccess ? (
                <div>
                    <Alert severity="success">Ihnen wurde eine E-Mail für die Passwort Zurücksetzung gesendet</Alert>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                <div>
                    <h6>Bitte geben Sie ihre E-Mail ein.</h6>
                    <TextField
                        label="E-Mail"
                        variant="outlined"
                        name="userMail"
                        type="email"
                        onChange={handleChange}
                    />
                    {error &&
                        <Alert className="mt-3" severity="error">{error}</Alert>
                    }
                    <div className="buttons">
                        <Button variant="contained" color="error" type="reset">Zurücksetzen</Button>
                        <Button variant="contained" disabled={loading} color="success" type="submit">Senden</Button>
                    </div>
                </div>
            </form>
            )}
        </div>
    );
}

export default ForgotPassword;