//import node modules and react modules
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Table from "../../../components/table/Table";
import { useSelector } from 'react-redux';
import { getToken, getUser } from '../../../features/user/userSlice';
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row
} from "react-bootstrap";
import * as Icons from "react-icons/fa";
//Import CSS
import '../../../css/App.css'
//import DWBO Sources
import Headline from '../../../components/Headline';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { responseNotifyHandling } from '../../../components/Error'
import AddDataLine from '../../../components/admin/AddDataLine';
import { updateProject, deleteProject, getProjects } from '../../../features/admin/projectManagementActions'
import ProjectForm from '../../../features/admin/forms/ProjectForm';
//import { DeleteConfirmation } from '../../../components/admin/ConfirmDeleteDialog'

//import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import apiConfig from '../../../config/config'
import { tableSelectNonPersonalConfig as tableSelectConfig } from '../../../forms/BH/disabilityAssistanceNonPersonalSelect';
import { deleteDatabaseObject, getDatabaseObjects } from '../managementActions/databaseObjectActions';
import { useLayoutEffect } from 'react';
import ServiceForm from './ServiceForm';
import dayjs from 'dayjs';

const initService = {
    service: "",
    effortConsultation: "",
    date: "",
    differentiationConsultation: "",
    serviceFor: "",
    shortNote: ""
};

const AnonymousDataCollection = () => {
    const [services, setServices] = useState([]);
    const [newService, setNewService] = useState(initService);
    const [show, setShow] = useState(false);

    //only used for delete confirmation
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const userToken = useSelector(getToken);
    const userInfo = useSelector(getUser);

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    //fetched projektdaten als json von db
    useLayoutEffect(() => {
        getData();
    }, []);

    function getData() {
        getDatabaseObjects(userToken, "/user/data/anonymousService").then((response) => {
            setServices(response.data);
        }).catch((err) => {
            responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}]: ${err.response.data.message}`);
        });
    }

    //wenn user geladen werden wird nur das auf der Seite angezeigt
    /*
    if (isLoading) {
        return <div className="vertCent">Loading...</div>;
    }
    */

    //schliesst edit pop-up
    function handleClose() {
        setShow(false);
    }
    //editing project
    function onEdit(newService) {
        setNewService({ ...newService });
        setShow(true);
    }
    function onDelete(element) {
        setNewService(element);
        setDisplayConfirmationModal(true);
    }
    function deleteConfirmed(element) {
        deleteDatabaseObject(element, userToken, "/user/data/anonymousService")
            .then((res) => {
                responseNotifyHandling(res, res.status, `Leistung wurde gelöscht`)
                getData();
            })
            .catch((err) => {
                responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}] beim löschen von Leistung.`)
            });
        setDisplayConfirmationModal(false);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Leistungen',
                disableSortBy: true,
                columns: [
                    {
                        Header: 'Leistung',
                        accessor: 'service',
                        Cell: ({ row }) => {
                            const configObject = tableSelectConfig.services.service.find(obj => obj.id === row.original.service);

                            if(configObject) {
                                return configObject.value
                            }
                            else return null;
                        }
                    },
                    {
                        Header: 'Beratungsaufwand',
                        accessor: 'effortConsultation',
                        Cell: ({ row }) => {
                            const configObject = tableSelectConfig.services.effortConsultation.find(obj => obj.id === row.original.effortConsultation);

                            if(configObject) {
                                return configObject.value
                            }
                            else return null;
                        }
                    },
                    {
                        Header: 'Datum',
                        accessor: 'date',
                        Cell: ({ row }) => {
                            if(!row.original.date) {
                                return null;
                            }

                            return dayjs(row.original.date).format('DD.MM.YYYY');
                        }
                    },
                    {
                        Header: 'Differenzieren der Leistung',
                        accessor: 'differentiationConsultation',
                        Cell: ({ row }) => {
                            const configObject = tableSelectConfig.services.differentiationConsultation.find(obj => obj.id === row.original.differentiationConsultation);

                            if(configObject) {
                                return configObject.value
                            }
                            else return null;
                        }
                    },
                    {
                        Header: 'Leistungserbringung an',
                        accessor: 'serviceFor',
                        Cell: ({ row }) => {
                            const configObject = tableSelectConfig.services.serviceFor.find(obj => obj.id === row.original.serviceFor);

                            if(configObject) {
                                return configObject.value
                            }
                            else return null;
                        }
                    },
                    {
                        Header: 'Kurzvermerk',
                        accessor: 'shortNote'
                    },
                    {
                        Header: 'Aktionen',
                        accessor: 'actions',
                        disableSortBy: true,
                        Cell: ({ row }) => (
                            <div className='d-flex' style={{ columnGap: "0.2rem" }}>
                                <Button
                                    variant="info"
                                    title="Bearbeiten"
                                    className={`btn-primary btn-sm`}
                                    onClick={() => onEdit(row.original)}>
                                    <Icons.FaPencilAlt />
                                </Button>{" "}
                                <Button
                                    variant="danger"
                                    title="Löschen"
                                    className={`btn-danger btn-sm`}
                                    onClick={() => onDelete(row.original)}
                                >
                                    <Icons.FaTrashAlt /></Button>
                            </div>
                        )
                    }
                ],
            }
        ],
        []
    )

    if(userInfo.userProject.projectType.id === 2) {
    return (
        <div>
            <Headline name='nicht-personenbezogene Leistungen' />
            <Container className="list-container anonymous-data-collection">
                <Row>
                    <Col>
                        <AddDataLine
                            name='Neue Leistung hinzufügen'
                            getData={getData}
                            form="service"
                            title='Leistung hinzufügen' />
                        <Table columns={columns} data={services} withFilter={false} initialSortColumn="date" />

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Leistung bearbeiten</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ServiceForm onEdit="1" setShow={setShow} getData={getData} serviceData={newService} />
                            </Modal.Body>
                        </Modal>

                        <ConfirmationDialog showModal={displayConfirmationModal} confirmModal={() => deleteConfirmed(newService)} hideModal={hideConfirmationModal} message="Soll die Leistung gelöscht werden?" title="Löschen bestätigen" btnCancelTxt="Abbrechen" btnSubmitTxt="Löschen" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
    }
    else return (
        <p>no permission</p>
    )
};
export default AnonymousDataCollection;