import { containerTypes, inputTypes, eventHandlers, containerLayouts, specialTypes } from "../../../config/configEnums";
import { formEventSelect } from "./formEventSelect";

export const event = {
    name: "Allgemeine Veranstaltungsdaten",
    key: "event",
    containers: [
        {
            containerType: containerTypes.GENERICFORM,
            containerLayout: containerLayouts.LIST,
            position: 1,
            cssClass: "bh-event-title",
            elements: [
                { name: "Veranstaltungtitel", inputType: inputTypes.HEADER },
                { name: "Veranstaltungsbezeichnung", value: "eventName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Veranstaltungsbeschreibung", value: "eventDescription", inputType: inputTypes.MULTILINE, maxRows: 2, eventHandler: eventHandlers.GENERICHANDLER }
            ]
        },
        {
            containerType: containerTypes.GENERICFORM,
            containerLayout: containerLayouts.LIST,
            position: 2,
            cssClass: "bh-event-type",
            elements: [
                { name: "Angaben zu Art, Turnus und Inhalt", inputType: inputTypes.HEADER },
                { name: "Veranstaltungsart", value: "kindOfEvent", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Veranstaltungsturnus", value: "intervalOfEvent", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Angebot an", value: "offerOnWeekdays", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER }
            ]
        },
        {
            containerType: containerTypes.GENERICFORM,
            containerLayout: containerLayouts.LIST,
            position: 3,
            cssClass: "bh-event-location",
            elements: [
                { name: "Ort der Veranstaltung", inputType: inputTypes.HEADER },
                { name: "Veranstaltungsort", value: "locationOfEvent", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER },
            ]
        },
        {
            containerType: containerTypes.GENERICFORM,
            containerLayout: containerLayouts.LIST,
            position: 4,
            cssClass: "bh-event-focus",
            elements: [
                { name: "Veranstaltungsschwerpunkt und Besucher*innenausrichtung", inputType: inputTypes.HEADER },
                { name: "Veranstaltungsschwerpunkt", value: "mainContentOfEvent", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Besucher*innenausrichtung", value: "orientationParticipants", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER },
            ]
        },
        {
            containerType: containerTypes.ACCORDION,
            containerLayout: containerLayouts.GRID3,
            position: 5,
            cssClass: "bh-event-disabilities",
            header: "Zielgruppe der Veranstaltung",
            elements: [
                { name: "Menschen mit:", inputType: inputTypes.HEADER },
                { name: "körperlicher Behinderung", value: "physicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "geistiger Behinderung", value: "mentalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "seelische Behinderung", value: "psychologicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Autismus", value: "autism", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Sinnesbehinderung", value: "sensoryDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Mehrfachbehinderung", value: "multipleDisabilities", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Menschen mit und ohne Behinderung", value: "withAndWithoutDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
            ]
        },
    ],
    selectConfigUserData: formEventSelect
}

export const eventStats = {
    name: "Termine und Besucher*innen",
    key: "eventStats",
    containers: [
        {
            containerType: containerTypes.ADDROWTABLECONTAINER,
            cssClass: "event-stats",
            position: 1,
            tables: [
                {
                    tableName: "eventStats",
                    elements: [
                        /* { name: "ID", value: "eventStatID", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, specialTypeField: specialTypes.WITHPROHIBITDUPLUCATESELECT }, */
                        { name: "Veranstaltungsdatum", value: "eventDate", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialTypeField: specialTypes.WITHAUTODATESET },
                        { name: "Anzahl Termine", value: "eventScheduleAmount", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER },
                        { name: "Summe Besucher*innen", value: "eventSumVisitors", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER },
                        { name: "davon weiblich", value: "eventVisitorsFemale", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER },
                        { name: "Veranstaltungsdauer", value: "eventDuration", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER }
                    ]
                }
            ]
        }
    ]
}

export const eventNotes = {
    name: "Vermerke",
    key: "eventNotes",
    containers:
        [
            {
                containerType: containerTypes.GENERICFORM,
                containerLayout: containerLayouts.LIST,
                position: 1,
                cssClass: "bh-eventNote-title",
                elements: [
                    { name: "Vermerke", value: "eventNotes", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 6 }
                ]
            }
        ]
}