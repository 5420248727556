//import node modules and react modules
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { responseNotifyHandling } from '../../../components/Error';
import { useSelector } from 'react-redux';
import axios from 'axios';
//Import CSS
import '../../../css/pages/adminspace.css';
import '../../../css/components/forms.css';
import '../../../css/App.css';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { tableSelectNonPersonalConfig as tableSelectConfig } from '../../../forms/BH/disabilityAssistanceNonPersonalSelect';
import dayjs from 'dayjs';
import { parseISO } from 'date-fns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getToken } from '../../../features/user/userSlice';
import { createDatabaseObject, updateDatabaseObject } from '../managementActions/databaseObjectActions';



let initService;

const initErrors = {
    service: null,
    date: null
}

const reportingYear = 2024;
const minDate = dayjs(new Date(`${reportingYear}-01-01`));
const maxDate = dayjs(new Date(`${reportingYear}-12-31`));

const ServiceForm = ({ setShow, getData, serviceData, onEdit }) => {
    if (onEdit === "1") {
        initService = serviceData;
    } else {
        initService = {
            service: "",
            effortConsultation: 1,
            date: "",
            differentiationConsultation: 1,
            serviceFor: 4,
            shortNote: ""
        }
    }

    const [form, setForm] = useState(initService);
    const [errors, setErrors] = useState(initErrors);

    const userToken = useSelector(getToken);

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    //validation regexpressions
    const projectNameFormat = /^[a-zA-Z0-9\x7f-\xff- ]+$/;
    const projectIDFormat = /^[0-9]*$/;

    /**
   * change event handler for input fields
   * @param {*} e 
   */
    function handleChange(e, custom) {

        // if date is not set and the service select was changed
        if (custom) {
            setForm(custom);
        }
        else {
            //set form values
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    async function createNewService(service) {
        createDatabaseObject(service, userToken, "/user/data/anonymousService")
            .then((res) => {
                setShow(false);
                getData();
                responseNotifyHandling(res, res.status, "Leistung wurde erstellt");
            })
            .catch((err) => {
                console.log(err)
                responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}] beim erstellen von Leistung`)
            })
    }

    async function updateNewService(service) {
        updateDatabaseObject(service, userToken, "/user/data/anonymousService")
            .then((res) => {
                setShow(false);
                getData();
                responseNotifyHandling(res, res.status, "Leistung wurde bearbeitet");
            })
            .catch((err) => {
                responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}] beim bearbeiten von Leistung`)
            })
    }

    function getTime(key) {
        if (form[key]) {
            if (typeof form[key] == "string") {
                return parseISO(form[key]);
            }
            if (typeof form[key] == "object") {
                return form[key];
            }
        }
        else return '';
    }

    const formValidation = () => {
        let formValid = true;
        if (!form.service) {
            formValid = false;
            setErrors({ ...errors, service: "Bitte Leistung angeben" });
        }

        if (errors.date) {
            formValid = false
        }

        return formValid
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const formValid = formValidation();

        if (formValid) {
            if (!onEdit) {
                createNewService(form);
            }
            else {
                updateNewService(form);
            }
        }
    };

    return (
        <form className='new-service-form' onSubmit={handleSubmit}>
            <FormControl error={!!errors.service}>
                <InputLabel id="service">Leistung</InputLabel>
                <Select
                    labelId="service"
                    name="service"
                    label="Leistung"
                    value={form.service}
                    onChange={(e) => {
                        const newForm = { ...form, [e.target.name]: e.target.value };
                        if (errors.service) {
                            setErrors({ ...errors, service: null })
                        }
                        if (!form.date) {
                            newForm.date = dayjs();
                        }
                        if(!(e.target.value === 1 || e.target.value === 2)) {
                            newForm.differentiationConsultation = 1;
                        }

                        if(e.target.value === 2) {
                            newForm.effortConsultation = 2;
                        }
                        else {
                            newForm.effortConsultation = 1;
                        }

                        handleChange(e, newForm);
                    }}
                >
                    {tableSelectConfig.services.service.map((element, i) => (
                        <MenuItem key={i} value={element.id}>{element.value}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{errors.service}</FormHelperText>
            </FormControl>
            <FormControl>
                <InputLabel id="effortConsultation">Beratungsaufwand</InputLabel>
                <Select
                    labelId="effortConsultation"
                    name="effortConsultation"
                    value={form.effortConsultation}
                    label="Beratungsaufwand"
                    disabled={form.service !== 2}
                    onChange={(e) => handleChange(e)}
                >
                    {tableSelectConfig.services.effortConsultation.map((element, i) => (
                        <MenuItem key={i} value={element.id}>{element.value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Form.Group controlId="formBasicData">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                    <DesktopDatePicker
                        label="Datum"
                        className='mui-input'
                        slotProps={{
                            textField: {
                                size: "normal",
                                field: {
                                    clearable: true, onClear: () => {
                                        setForm({ ...form, date: "" });
                                    }
                                },
                                ...((errors.date === "minDate" || errors.date === "maxDate") && { helperText: "Datum befindet sich außerhalb des Erfassungszeitraums" })
                            }
                        }}
                        value={form.date ? dayjs(getTime("date")) : null}
                        onError={(error) => {
                            setErrors({ ...errors, date: error });
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date) => {
                            setForm({ ...form, date: date });
                        }}
                    />
                </LocalizationProvider>
            </Form.Group>
            <FormControl>
                <InputLabel id="differentiationConsultation">Differenzieren der Leistung</InputLabel>
                <Select
                    labelId="differentiationConsultation"
                    name="differentiationConsultation"
                    value={form.differentiationConsultation}
                    disabled={!(form.service === 1 || form.service === 2)}
                    label="Differenzieren der Leistung"
                    onChange={(e) => handleChange(e)}
                >
                    {tableSelectConfig.services.differentiationConsultation.map((element, i) => (
                        <MenuItem key={i} value={element.id}>{element.value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="serviceFor">Leistungserbringung an</InputLabel>
                <Select
                    labelId="serviceFor"
                    name="serviceFor"
                    value={form.serviceFor}
                    label="Leistungserbringung an"
                    onChange={(e) => handleChange(e)}
                >
                    {tableSelectConfig.services.serviceFor.map((element, i) => (
                        <MenuItem key={i} value={element.id}>{element.value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <TextField
                    label="Kurzvermerk"
                    value={form.shortNote || ''}
                    name="shortNote"
                    size="normal"
                    multiline
                    maxRows={2}
                    onChange={(e) => handleChange(e)}
                />
            </FormControl>
            <div className="button-row">
                <Button variant="secondary" style={{ marginRight: '3.25em' }} onClick={(e) => setShow(false)}>
                    Abbrechen
                </Button>
                <Button variant="primary" type="submit">
                    {onEdit ? "Leistung bearbeiten" : "Leistung hinzufügen"}
                </Button>
            </div>
        </form>
    );

};

export default ServiceForm;