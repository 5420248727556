export const tableSelectNonPersonalConfig = {
    services: {
        service: [
            {
                id: 1,
                value: "Information"
            },
        //Diese Auswahl  bei personenbezogener und nicht-personenbezogener Leistungserfassung einblenden
            {
                id: 2,
                value: "Beratung"
            },
        
        //ab hier ändert sich Reihenfolge, da Id: 3 = Vermittlung entfällt
            
            //id: 4 Diese Auswahl bei personenbezogener und nicht-personenbezogener Leistungserfassung einblenden
            {
                id: 4,
                value: "Vermittlung an Fachkräfte bzw. Fachstellen"
            },
            //id: 5 Diese Auswahl bei personenbezogener und nicht-personenbezogener Leistungserfassung einblenden
            {
                id: 5,
                value: "Vermittlung an Selbsthilfegruppen / Ehrenamtliche Initiativen"
            },
        
            //Die Ids 9 und 10 bei personenbezogener und nicht-personenbezogener Leistungserfassung einblenden
            {
                id: 9,
                value: "Vermittlung an andere"
            },
            {
                id: 10,
                value: "Sonstige Leistungen"
            }
        ],

        //Tabelle Leistungen
        //Auswahl zu Differenzierung der Leistungen Beratung und Information
        differentiationConsultation: [
            {
                id: 1,
                value: "Keine Angabe"
            },
            {
                id: 2,
                value: "Persönlich im Büro"
            },
            {
                id: 3,
                value: "Persönlich vor Ort"
            },

            {
                id: 4,
                value: "Schriftlich / auch Internet / Mail"
            },

            {
                id: 5,
                value: "Per Video"
            },
            {
                id: 6,
                value: "Telefonisch"
            }
        ],

        //Tabelle Leistungen
        //Auswahl zu Beratungsaufwand
        effortConsultation: [
            {
                id: 1,
                value: "Keine Angabe"
            },
            {
                id: 2,
                value: "Bis 30 Minuten"
            },
            {
                id: 3,
                value: "Bis 90 Minuten"
            },

            {
                id: 4,
                value: "Bis 180 Minuten"
            },

            {
                id: 5,
                value: "Mehr als 180 Minuten"
            }
        ],

        //Tabelle Leistungen
        //Auswahl zu Leistung an
        serviceFor: [
            {
                id: 1,
                value: "Keine Angabe"
            },
            {
                id: 2,
                value: "Mensch mit Behinderung (im direkten Kontakt)"
            },
            {
                id: 3,
                value: "Familie, rechtl. Betreuer*innen etc."
            },
            {
                id: 4,
                value: "Fachkräfte und Fachstellen"
            },
            {
                id: 5,
                value: "Selbsthilfegruppen"
            },

            {
                id: 6,
                value: "Ehrenamtliche Helfer*innen"
            },

            {
                id: 7,
                value: "Versorgungseinrichtungen"
            },
            {
                id: 8,
                value: "Persönliche Arbeitsassistenz"
            },
            {
                id: 9,
                value: "Sonstige"
            }
        ]
    }
}