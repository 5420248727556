import { containerTypes, eventHandlers, inputTypes } from "../../config/configEnums";

export const projectData = {
    name: "Träger-/Projektangaben",
    key: "projectData",
    containers: [
        {
            containerType: containerTypes.GENERICFORM,
            position: 1,
            cssClass: "bh-project",
            elements: [
                { name: "Projekt:", inputType: inputTypes.HEADER },
                { name: "Zuwendungsjahr", value: "grantYear", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER, maxLength: 4 },
                { name: "Projekttitel", value: "projectTitle", inputType: inputTypes.MULTILINE, maxRows: 2, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Str. + Hausnr.", value: "projectStreet", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Postleitzahl", value: "projectPostalCode", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Ort", value: "projectCity", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Telefon", value: "phone", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Verband", value: "association", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER },
            ]
        },
        {
            containerType: containerTypes.ACCORDION,
            position: 2,
            cssClass: "bh-project-disabilities",
            name: "Angaben zur Projektzielgruppe",
            elements: [
                { name: "Menschen mit:", inputType: inputTypes.HEADER },
                { name: "körperlicher Behinderung", value: "physicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "geistiger Behinderung", value: "mentalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "seelischer Behinderung", value: "psychologicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Sinnesbehinderung", value: "sensoryDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Mehrfachbehinderung", value: "multipleDisabilities", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Autismus", value: "autism", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Mit und ohne Behinderung", value: "withAndWithoutDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                { name: "Anderes", value: "otherDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
            ]
        },
        {
            containerType: containerTypes.BASICTABLECONTAINER,
            header: "Mitarbeiter*innen",
            tableHead: [{ name: "Mitarbeiter*innen", align: "left" }, { name: "Anzahl", align: "center" }],
            cssClass: "employees",
            position: 3,
            elements: [
                [{ name: "Mitarbeiter*innen gesamt", inputType: inputTypes.DISPLAY }, { value: "numberOfEmployees", inputType: inputTypes.NUMBER, defaultValue: 0 }],
                [{ name: "Ehrenamt", inputType: inputTypes.DISPLAY }, { value: "volunteerWorkers", inputType: inputTypes.NUMBER, defaultValue: 0 }],
                [{ name: "davon mit Vorkenntnissen/Vorerfahrung", inputType: inputTypes.DISPLAY }, { value: "employeePreviousExperience", inputType: inputTypes.NUMBER, defaultValue: 0 }],
                [{ name: "davon Frauen", inputType: inputTypes.DISPLAY }, { value: "femaleEmployees", inputType: inputTypes.NUMBER, defaultValue: 0 }],
            ]
        },
        {
            containerType: containerTypes.GENERICFORM,
            position: 4,
            cssClass: "moreServices",
            elements: [
                { name: "Weitere Leistungen:", inputType: inputTypes.HEADER },
                { name: "Information", value: "information", inputType: inputTypes.NUMBER},
            ]
        },
        {
            containerType: containerTypes.ADDROWTABLECONTAINER,
            cssClass: "project-location-table",
            position: 5,
            tables: [
                {
                    tableName: "projectLocation",
                    header: "Standorte",
                    elements: [
                        { name: "Standort", inputType: inputTypes.TEXT },
                    ]
                },
            ]
        }
    ],
    selectConfigUserData: {
        association: [
            {
                id: 1,
                value: "keine Angabe"
            },
            {
                id: 2,
                value: "AWO Landesverband Berlin e.V."
            },
            {
                id: 3,
                value: "Caritas für das Erzbistum Berlin e.V."
            },
            {
                id: 4,
                value: "Deutscher Paritätischer Wohlfahrtsverband - Landesverband Berlin e.V."
            },
            {
                id: 5,
                value: "Deutsches Rotes Kreuz - Landesverband Berlin e.V."
            },
            {
                id: 6,
                value: "Diakonisches Werk Berlin-Brandenburg-schlesische Oberlausitz e.V."
            },
            {
                id: 7,
                value: "Jüdische Gemeinde zu Berlin - Körperschaft des öffentlichen Rechts -"
            }
        ]
    }
}