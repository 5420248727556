import { containerTypes, inputTypes, eventHandlers, specialTypes, containerLayouts } from "../../config/configEnums"
import { selectConfigUserData, tableSelectConfig } from "./delinquentAssistanceSelect"

export const delinquentAssistanceConfig = {
    dataCollection: {
        containers: [
            {
                containerType: containerTypes.GENERICFORM,
                cssClass: "user-info-table",
                position: 1,
                elements: [
                    { name: "Nachname / Pseudonym", value: "lastName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER, required: true },
                    { name: "Vorname", value: "firstName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Geburtsdatum", value: "birthday", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialType: [specialTypes.WITHAGEFIELD] },
                    { name: "Alter", inputType: inputTypes.DISPLAY, specialTypeField: specialTypes.WITHAGEFIELD },
                    { name: "Str. + Hausnr", value: "addressStreet", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Ort", value: "addressCity", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Telefon", value: "phone", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Beratung abgeschlossen", value: "consultationComplete", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, style: "slider" },
                ]
            },
            {
                containerType: containerTypes.ACCORDION,
                containerLayout: containerLayouts.GRID3,
                position: 2,
                cssClass: "sociodemographic",
                specialTypeContainer: [specialTypes.WITHCHECKREMOVE, "underage"],
                header: "Soziodemografische Angaben",
                elements: [
                    { name: "Geschlecht", value: "gender", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Muttersprache", value: "motherTongue", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Staatsangehörigkeit", value: "nationality", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Haushaltsstruktur", value: "householdStructure", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Einkommen", value: "income", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Erwerbstätigkeit", value: "employment", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Unterkunft", value: "housing", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Wohnungsnotfall", value: "housingEmergency", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Krankenversicherung", value: "healthInsurance", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Vermittelt von", value: "arrangedBy", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Geflüchtete Menschen", value: "refugeeStatus", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Status nach Abschluss des Beratungsprozesses", value: "statusAfterConsultation", inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Anzahl minderjähriger Kinder im Haushalt", value: "householdUnderageChildren", inputType: inputTypes.NUMBER, max: 10, export: true },
                    { name: "Alter Kind", value: "underageChildrenAges", inputType: inputTypes.NUMBER, specialTypeField: specialTypes.WITHDISPLAYNUMBEROFFIELDS, depends: "householdUnderageChildren", max: 18 }
                ]
            },
            {
                containerType: containerTypes.ACCORDION,
                containerLayout: containerLayouts.LIST,
                position: 3,
                header: "Weitere Informationen",
                elements: [
                    { name: "", value: "extraField1", inputType: inputTypes.TEXT },
                    { name: "Bearbeiter / Bezugsberater*innen", value: "arranger", inputType: inputTypes.TEXT },
                    { name: "Beratungsverlauf", value: "consultationNotes", inputType: inputTypes.MULTILINE, maxRows: 6 }
                ]
            },
            {
                containerType: containerTypes.ADDROWTABLECONTAINER,
                position: 4,
                tables: [
                    {
                        tableName: "problemAreas",
                        elements: [
                            { name: "Problemfelder", value: "id", required: true, inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, specialTypeField: specialTypes.WITHPROHIBITDUPLUCATESELECT, setDateOnSelect: true, export: true },
                            { name: "Datum", value: "date", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialTypeField: specialTypes.WITHAUTODATESET }
                        ]
                    },
                    {
                        tableName: "objectives",
                        elements: [
                            { name: "Zielerreichung", value: "id", required: true, inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, setDateOnSelect: true, export: true },
                            { name: "Datum", value: "date", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialTypeField: specialTypes.WITHAUTODATESET }
                        ]
                    },
                    {
                        tableName: "services",
                        specialTypeTable: [specialTypes.WITHEXTRACOLUMNS, "uebernachtung", "userProject.projectOffer.projectOfferType"],
                        elements: [
                            { name: "Leistung", value: "id", required: true, inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, filterNot: 1, setDateOnSelect: true, export: true },
                            { name: "Datum", value: "date", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialTypeField: specialTypes.WITHAUTODATESET },
                        ]
                    }
                ]
            }
        ],

        tableSelectConfig: tableSelectConfig,

        //Soziodemografische Daten: allgemeine Auswahl der Selectboxen
        selectConfigUserData: selectConfigUserData
    },

    project: {
        containers: [
            {
                containerType: containerTypes.GENERICFORM,
                containerLayout: containerLayouts.LIST,
                cssClass: "project-description",
                position: 1,
                elements: [
                    { name: "Beschreibung", value: "userDescription", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 5 }
                ]
            },
            {
                containerType: containerTypes.GENERICFORM,
                cssClass: "project-address",
                inputVariant: "standard",
                position: 2,
                elements: [
                    { name: "Trägeradresse:", inputType: inputTypes.HEADER },
                    { name: "Str. + Hausnr.", value: "holderStreet", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Postleitzahl", value: "holderPostalCode", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Ort", value: "holderCity", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Projektadresse:", inputType: inputTypes.HEADER },
                    { name: "Str. + Hausnr.", value: "projectStreet", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Postleitzahl", value: "projectPostalCode", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Ort", value: "projectCity", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                ]
            },
            {
                containerType: containerTypes.BASICTABLECONTAINER,
                header: "Mitarbeiter*innen",
                tableHead: [{ name: "BDS", align: "left" }, { name: "Mitarbeiter*innen", align: "left" }, { name: "Anzahl", align: "center" }, { name: "davon weiblich", align: "center" }],
                position: 3,
                elements: [
                    [{ name: "02", inputType: inputTypes.DISPLAY }, { name: "Ehrenamtliche Mitarbeiter*innen", inputType: inputTypes.DISPLAY }, { value: "volunteerWorkers", inputType: inputTypes.NUMBER, defaultValue: 0 }, { value: "volunteerWorkersF", inputType: inputTypes.NUMBER, defaultValue: 0 }],
                    [{ name: "02a", inputType: inputTypes.DISPLAY }, { name: "davon neu hinzugekommen", inputType: inputTypes.DISPLAY }, { value: "volunteerWorkersNew", inputType: inputTypes.NUMBER, defaultValue: 0 }, { value: null, inputType: inputTypes.NUMBER }],
                    [{ name: "98", inputType: inputTypes.DISPLAY }, { name: "Sonstige", inputType: inputTypes.DISPLAY }, { value: "otherWorkers", inputType: inputTypes.NUMBER, defaultValue: 0 }, { value: "otherWorkersF", inputType: inputTypes.NUMBER, defaultValue: 0 }],
                    [{ name: "00", inputType: inputTypes.DISPLAY }, { name: "Keine Angaben", inputType: inputTypes.DISPLAY }, { value: "workersNoInfo", inputType: inputTypes.NUMBER, defaultValue: 0 }, { value: "workersNoInfoF", inputType: inputTypes.NUMBER, defaultValue: 0 }],
                ]
            }
        ]
    }
}