import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal
} from "react-bootstrap";
import axios from 'axios';
import apiConfig from '../../../config/config';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import GenericForm from "../../../components/GenericForm";
import { Typeahead } from "react-bootstrap-typeahead";
import { disabilityAssistanceConfig } from "../../../forms/BH/disabilityAssistanceConfig";
import { getInitDatabaseObject } from "../../../config/configEnums";
import { useSelector, useDispatch } from 'react-redux';
import * as Icons from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai"
import { MdClear } from 'react-icons/md';
import { getToken, getFormConfig } from '../../../features/user/userSlice';
import { responseNotifyHandling } from '../../../components/Error';
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { updateEvent, createEvent, deleteEvent } from "../../../features/admin/eventManagementActions";
import { getFormState, setFormState, FormState, getErrors, setErrors, setFormCheckDone, getFormCheckDone, FormCheckDoneOptions } from '../../../features/form/formSlice';
import { getSelectedEvent, setSelectedEvent } from "../../../forms/BH/events/eventSlice";

const FormMode = {
  Create: "Create",
  Update: "Update"
}

function Event() {
  const fullFormConfig = useSelector(getFormConfig);
  const urlSplit = window.location.href.split('/');
  const formConfig = fullFormConfig[urlSplit[urlSplit.length - 1]];

  const [initCurrentEventData, setInitCurrentEventData] = useState(getInitDatabaseObject(formConfig));
  const [newEvent, setNewEvent] = useState(initCurrentEventData);

  const [allEvents, setAllEvents] = useState([]);
  const [updatedEvent, setUpdatedEvent] = useState(initCurrentEventData);

  const [formMode, setFormMode] = useState(FormMode.Create);
  const [radioValue, setRadioValue] = useState(null);
  const [loading, setLoading] = useState(false);

  //Modal confirm
  const [showModal, setShowModal] = useState(false);
  const [confirmFor, setConfirmFor] = useState('');
  const [titleModal, setTitleModal] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [btnCancelModal, setBtnCancelModal] = useState('');
  const [btnSubmitModal, setBtnSubmitModal] = useState('');
  const [btnSubmitColor, setBtnSubmitColor] = useState('danger');

  const currentUser = JSON.parse(localStorage.getItem('user'));

  const tableSelectedEvent = useSelector(getSelectedEvent);
  const formState = useSelector(getFormState);
  const errors = useSelector(getErrors);
  const formCheckDone = useSelector(getFormCheckDone);

  const dispatch = useDispatch();

  const userToken = useSelector(getToken);

  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }

  //fetch event data from db
  useEffect(() => {
    getEventData();
    dispatch(setFormState(FormState.UNCHANGED))
    dispatch(setErrors({}));
  }, []);

  //operations after form is checked
  useEffect(() => {
    if (formCheckDone === FormCheckDoneOptions.YES) {
      if (Object.keys(errors).length === 0) {
        formMode === FormMode.Create ? setConfirmFor("CREATE") : setConfirmFor("UPDATE")

        //confirm dialog
        if (formMode === FormMode.Create) {
          setMessageModal(newEvent.eventName ? `Soll das neue Event [${newEvent.eventName}] gespeichert werden?` :
            "Soll das neue Event gespeichert werden?");
        }
        else {
          setMessageModal(newEvent.eventName ? `Sollen die Änderungen am Event [${newEvent.eventName}] gespeichert werden?` :
            "Sollen die Änderungen am Event gespeichert werden");
        }
        setTitleModal("Ungesicherte Änderungen");
        setBtnCancelModal("Abbrechen");
        setBtnSubmitModal("Speichern");
        setBtnSubmitColor("success");
        setShowModal(true);
      }

      dispatch(setFormCheckDone(""));
    }
  }, [formCheckDone]);

  useEffect(() => {
    if (tableSelectedEvent !== null) {
      if (newEvent?._id === tableSelectedEvent._id) {
        dispatch(setSelectedEvent(null));
      }
    }
  }, [newEvent]);

  useEffect(() => {
    const handler = event => {
      event.preventDefault();
      event.returnValue = '';
    };
    // if the form is NOT unchanged, then set the onbeforeunload
    if (formState !== FormState.UNCHANGED) {
      window.addEventListener('beforeunload', handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => { };
  }, [formState]);

  async function getEventData() {
    setLoading(true);
    document.body.style.cursor = "progress";
    //get all events
    let projID = currentUser.userProject._id;
    await axios.get(`${apiConfig.rest}/user/data/listEventsBH/${projID}`, config).then((response) => {
      response.data.sort((a, b) => {
        return a.eventName.toLowerCase().localeCompare(b.eventName.toLowerCase());
      })
      setAllEvents(response.data);
      if (tableSelectedEvent && Object.keys(newEvent).every(element => Object.keys(tableSelectedEvent).includes(element))) {
        handleSelectEvent(tableSelectedEvent, true);
      }
      setLoading(false);
      document.body.style.cursor = "auto";
    }).catch((err) => {
    });
  }

  function handleChange(e, check) {
    dispatch(setFormState(FormState.MODIFIED));
    if (!check) {
      setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
    }
    else {
      setNewEvent({ ...newEvent, [e.target.name]: e.target.checked });
    }
  }

  const eventCreate = () => {
    let createThisEvent = { ...newEvent, project: currentUser.userProject._id }

    createEvent(createThisEvent, userToken)
      .then((res) => {
        if (res.status === 201) {
          responseNotifyHandling(res, res.status, newEvent.eventName ? `Event: [${newEvent.eventName}] wurde erstellt` :
            "Event wurde erstellt");
          handleSelectEvent(res.data, true);
          getEventData();
          dispatch(setFormState(FormState.UNCHANGED));
        }
      })
      .catch((err) => {
        responseNotifyHandling(err.response, err.response.status, newEvent.eventName ? `Fehler [${err.response.status}] beim erstellen von Event ${newEvent.eventName}.` :
          `Fehler [${err.response.status}] beim erstellen von Event.`);
      });
  }

  const eventUpdate = () => {
    updateEvent(newEvent, userToken)
      .then((res) => {

        if (res.status === 200) {
          responseNotifyHandling(res, res.status, newEvent.eventName ? `Event: [${newEvent.eventName}] wurde aktualisiert` :
            "Event wurde aktualisiert");
          getEventData();
          setUpdatedEvent(newEvent);
          setNewEvent(newEvent);
          dispatch(setFormState(FormState.UNCHANGED));
        }
      })
      .catch((err) => {
        console.log(err)
        responseNotifyHandling(err.response, err.response.status, newEvent.eventName ? `Fehler [${err.response.status}] beim aktualisieren von Event ${newEvent.eventName}.` :
          `Fehler [${err.response.status}] beim aktualisieren von Event.`);
      });
  }

  const eventDelete = () => {
    deleteEvent(newEvent, userToken)
      .then((res) => {
        if (res.status == 204) {
          responseNotifyHandling(res, res.status, newEvent.eventName ? `Event: [${newEvent.eventName}] wurde gelöscht` :
            "Event wurde gelöscht");
          dispatch(setFormState(FormState.UNCHANGED));
          resetForm();
        }
      })
      .catch((err) => {
        responseNotifyHandling(err.response, err.response.status, newEvent.eventName ? `Fehler [${err.response.status}] beim löschen von Event ${newEvent.eventName}.` :
          `Fehler [${err.response.status}] beim löschen von Event.`)
      });
  }

  const resetForm = () => {
    getEventData();
    setNewEvent(initCurrentEventData);
    setUpdatedEvent(initCurrentEventData);
    setFormMode(FormMode.Create);
  }

  const handleDeleteEventData = (e) => {
    setConfirmFor("DELETE");

    setMessageModal(newEvent.eventName ? `Soll das Event [${newEvent.eventName}] gelöscht werden?` :
      "Soll das Event gelöscht werden?");
    setTitleModal("Event löschen");
    setBtnCancelModal("Abbrechen");
    setBtnSubmitModal("Löschen");
    setBtnSubmitColor("danger");
    setShowModal(true);
  }

  async function handleSelectEvent(element, create) {
    let event;
    if (create) {
      event = element;
    }
    else {
      await axios.get(`${apiConfig.rest}/user/data/getEvent/${element._id}`, config).then((response) => {
        if (response.status === 200) {
          event = response.data
        }
      }).catch((err) => {
        responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}]: Event konnte nicht gefunden werden.`)
      });
    }

    setUpdatedEvent(event);
    setNewEvent(event);
    setFormMode(FormMode.Update);
    dispatch(setErrors({}));
  }

  const closeModal = () => {
    setShowModal(false);
  }
  const openModal = () => {
    setShowModal(true);
  }

  const handleEventData = (event) => {
    let editedEvent;
    //editedEvent = removeNullRows(event)
    //editedEvent = resetDisabledData(editedEvent);
    return editedEvent;
  }

  const handleConfirmModal = () => {
    dispatch(setFormState(FormState.SAVING));
    switch (confirmFor) {
      case "CREATE":
        eventCreate();
        break;
      case "UPDATE":
        eventUpdate();
        break;
      case "DELETE":
        eventDelete();
        break;
      default:
        //TODO
        break;
    }
    closeModal();
  }

  function typeaheadPlaceholder() {
    if (loading) {
      return "";
    }
    else if (allEvents.length === 0) {
      return "Keine Events gefunden";
    }
    return "-- Event auswählen --";
  }

  return (
    <div className="events" style={{ width: '100%' }}>
      <ConfirmationDialog
        showModal={showModal}
        confirmModal={() => handleConfirmModal()}
        hideModal={() => closeModal()}
        message={messageModal}
        title={titleModal}
        btnCancelTxt={btnCancelModal}
        btnSubmitTxt={btnSubmitModal}
        btnSubmitColor={btnSubmitColor}
      />
      {/* <Button
        variant="success"
        className="save mb-4"
        onClick={() => console.log(newEvent)}>
        <Icons.FaSave style={{ transform: "translate(-0.25rem, -0.09rem)" }} />
        Test
      </Button> */}
      <h5 className='mt-3 mb-4 mx-auto p-0' style={{ width: "fit-content" }}>{formMode === FormMode.Create ? "Event erstellen" : "Event aktualisieren"}</h5>
      <div className='d-flex p-0 mx-auto header-buttons' style={{ width: "100%" }}>
        {formMode === FormMode.Create ? (
          <Button
            variant='success'
            onClick={() => dispatch(setFormCheckDone(FormCheckDoneOptions.CHECKING))}>
            <AiOutlinePlus
              size={20}
              style={{ margin: "0 0.18rem 0.13rem 0" }} />Event erstellen</Button>
        ) : (
          <Button
            onClick={() => dispatch(setFormCheckDone(FormCheckDoneOptions.CHECKING))}
            variant="success">
            <Icons.FaSave
              style={{
                margin: "0 0.24rem 0.15rem 0"
              }} />Speichern</Button>
        )}
        {formMode === FormMode.Update &&
          <div>
            <Button
              variant='danger'
              onClick={(e) => handleDeleteEventData(e)}><Icons.FaTrash size={15} style={{ transform: 'translate(-0.2rem, -0.13rem)' }} />Datensatz löschen</Button>
          </div>
        }
      </div>
      <div style={{ height: "2.7rem", width: "100%", position: "relative" }} className={`row typeahead ${radioValue ? "filter-active" : ""}`}>
        <Typeahead
          key={Object.values(updatedEvent)}
          id="clientList"
          controlId="clientList"
          options={allEvents}
          onChange={(element) => {
            if (element.length !== 0) {
              handleSelectEvent(element[0]);
              setFormMode(FormMode.Update);
            }
          }}
          onInputChange={(element) => {
            if (element === "") {
              resetForm();
            }
          }}
          labelKey={(option) => {
            if (option.eventName) {
              return `${option.eventName}`;
            }
            else {
              return option.eventName;
            }
          }}
          defaultSelected={[updatedEvent]}
          placeholder={typeaheadPlaceholder()}
          disabled={allEvents.length === 0}
        />
        {/* TODO */}
        {updatedEvent.eventName &&
          <Button
            style={{ position: "absolute", width: "fit-content", right: 10, top: 2.2 }}
            className='clear-filter'
            onClick={() => resetForm()}>
            <MdClear size="23" />
          </Button>
        }
      </div>
      <Tabs
        defaultActiveKey={disabilityAssistanceConfig.event.tabs[0].key}
        className=""
        justify
      >
        {disabilityAssistanceConfig.event.tabs.map((tab) => (
          <Tab key={tab.key} eventKey={tab.key} title={tab.name} disabled={formMode === FormMode.Create && tab.key !== "event" ? true : false}>
            <GenericForm
              formConfig={tab}
              databaseObject={newEvent}
              setDatabaseObject={setNewEvent}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}

export default Event;