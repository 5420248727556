export const tableSelectOvernightFamilyConfig = {
    services: {
        id: [
            {
                id: 1,
                value: "Beratung"
            },
            {
                id: 2,
                value: "Begleitung"
            },
        
            {
                id: 3,
                value: "Vermittlung an soziale Dienste/Institutionen/Einrichtungen"
            },
                     
            {
                id: 4,
                value: "Vermittlung an Fachberatungen"
            },
            {
                id: 5,
                value: "Vermittlung an Sonstige"
            },
            {
                id: 6,
                value: "Verfügungsmittel/Barbeihilfen (Passfotos/Fahrscheine u.ä.)"
            },
            {
                id: 7,
                value: "Vermittlung an Angebote des Gesundheitssystems"
            },
            {
                id: 8,
                value: "Körperpflege/Hygiene (Dusche, Bad)"
            },
            {
                id: 9,
                value: "Schließfachvergabe"
            },
            {
                id: 10,
                value: "Wäschereinigung"
            },
            {
                id: 11,
                value: "Bekleidungsausgabe"
            },
        
        /*Id:12 nur bei Angebotstypen Notübernachtung und Notübernachtung für Familien*/
            {
                id: 12,
                value: "Übernachtung"
            },
        
            {
                id: 13,
                value: "Vermittlung an ordnungsrechtliche Unterbringung"
            },
            {
                id: 14,
                value: "Vermittlung wegen Umsetzung von Ansprüchen auf SGB II-/SGB XII-Leistungen/Rentenansprüchen"
            },
            {
                id: 15,
                value: "Vermittlung an Fachdienst für Arbeit"
            },
            {
                id: 16,
                value: "Vermittlung von Hilfen zur Rückkehr ins Heimatland/Weiterwanderung"
            },
            {
                id: 17,
                value: "Vermittlung an Angebote nach SGB VIII"
            },
            {
                id: 18,
                value: "Vergabe Postadresse"
            },
            {
                id: 19,
                value: "Vermittlung in betreutes Wohnen gemäß SGB VIII, IX und XII"
            },
            {
                id: 20,
                value: "Vermittlung einer Wohnung"
            },
        ]
    },

    problemAreas: {
        id: [
            {
                id: 1,
                value: "Strafrechtliche Situation"
            },
            {
                id: 2,
                value: "Wohnsituation"
            },
            {
                id: 3,
                value: "Arbeit, Ausbildung"
            },
            {
                id: 4,
                value: "Schulden"
            },
            {
                id: 5,
                value: "Materielle Absicherung"
            },
            {
                id: 6,
                value: "Gesundheit"
            },
            {
                id: 7,
                value: "Psychosoziale Stabilität"
            },
            {
                id: 8,
                value: "Sucht"
            },
            {
                id: 9,
                value: "Soziale Kontakte (Verwandte, Bekannte)"
            },
            {
                id: 10,
                value: "Umgang mit Behörden"
            },
            {
                id: 11,
                value: "Migrationsspezifische Probleme"
            },
            {
                id: 12,
                value: "Gewaltbereitschaft"
            },
            {
                id: 13,
                value: "Gewalterfahrung"
            },
            {
                id: 14,
                value: "Hygiene"
            },
            {
                id: 15,
                value: "Schwangerschaft"
            },
            {
                id: 16,
                value: "Gerichtliche Auflagen"
            },
        
        /*Die Ids 17 bis 23 sollten nur bei Angebotstyp "Notübernachtung für Familien" sichtbar sein*/
        
            {
                id: 17,
                value: "Kitabesuch"
            },
        
            {
                id: 18,
                value: "Schulbesuch (nur bei Kindern)"
            },
            {
                id: 19,
                value: "Häusliche Gewalt (nur bei Kindern)"
            },
            {
                id: 20,
                value: "Kindesvernachlässigung (nur bei Kindern)"
            },
            {
                id: 21,
                value: "Kindesmisshandlung (nur bei Kindern)"
            },
            {
                id: 22,
                value: "Sexueller Missbrauch (nur bei Kindern)"
            },
            {
                id: 23,
                value: "Autonomiekonflikte (nur bei Kindern)"
            },
        
        /*Id:24 soll wieder für alle Angebotstypen sichtbar sein*/
            {
                id: 24,
                value: "Sonstiges"
            }
        ]
    },

    objectives: {
        id: [
            {
                id: 1,
                value: "Vermittlung an soziale Dienste"
            },
            {
                id: 2,
                value: "Vermittlung an Angebote des Gesundheitssystems"
            },
            {
                id: 3,
                value: "Vermittlung einer ordnungrechtlichen Unterbringung"
            },
        
        /*Id:4 wurde umbenannt*/
            {
                id: 4,
                value: "Umsetzung von Ansprüchen auf SGB II-/SGB XII-Leistungen/Rentenansprüchen"
            },
            {
                id: 5,
                value: "Vermittlung an Fachdienst für Arbeit"
            },
            {
                id: 6,
                value: "Erhalt der Wohnung"
            },
            {
                id: 7,
                value: "Wohnung vermittelt"
            },
            {
                id: 8,
                value: "Vermittlung in betreutes Wohnen"
            },
            {
                id: 9,
                value: "Erlangung Versichertenstatus"
            },
            {
                id: 10,
                value: "Vermittlung zurück ins soziale Umfeld"
            },
            {
                id: 11,
                value: "Vermittlung von Hilfen zur Rückkehr ins Heimatland/Weiterwanderung"
            },
        
            {
                id: 12,
                value: "Vermittlung an Angebot nach SGB VIII"
            },
        ]
    }
}