import axios from 'axios'
import { responseNotifyHandling } from '../../components/Error'
import apiConfig from '../../config/config'

//==========================Client==========================\\
export async function updateClient(client, config) {
    return axios.put(`${apiConfig.rest}/user/data/client/` + client._id, { client }, config);
}
export async function deleteClient(client, config) {
    return axios.delete(`${apiConfig.rest}/user/data/client/` + client._id, config)
}

export async function createClient(client, config) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    client = {...client }
    return axios.post(`${apiConfig.rest}/user/data/client/`, { client }, config)
}

export async function getExportData(formConfig, userToken) {
    const config = getConfig(userToken);
    axios.post(`${apiConfig.rest}/user/data/excelExport`, { formConfig }, config)
}

export async function getClientsByDates(dates, userToken) {
    const config = getConfig(userToken);

    return axios.post(`${apiConfig.rest}/user/data/clientByDate`, { dates }, config);
}

function getConfig(userToken, getProp) {
    const config = {
        ...(getProp && { params: getProp }),
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    };

    return config;
}