import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getFormConfig, getToken, getUser } from "../../../features/user/userSlice";
import React, { useEffect, useState } from "react";
import { getClientsByDates } from "../../../features/user/clientDataManagement";
import Headline from "../../../components/Headline";
import Table from "../../../components/table/Table";
import { Form } from "react-bootstrap";
import Datepicker from "../../../components/Datepicker";
import moment from "moment";
import { inputTypes, reportYear, specialTypes } from "../../../config/configEnums";
import axios from "axios";
import apiConfig from "../../../config/config";
import { Button, Alert } from "@mui/material";
import { CellFill, IgrExcelModule, Workbook, WorkbookFormat } from "igniteui-react-excel";
import { BeatLoader } from "react-spinners";
import { ExcelUtility } from "../excelExport/ExcelUtility";

IgrExcelModule.register();

const initForm = {
    exportFrom: new Date(Date.UTC(Number(reportYear), 0, 1)),
    exportTo: new Date()
}

const ClientExport = () => {
    const minDate = dayjs(`January 1, ${reportYear}`);
    const maxDate = dayjs(`December 31, ${reportYear}`);

    const userToken = useSelector(getToken);
    const formConfig = useSelector(getFormConfig);
    const user = useSelector(getUser);

    const createWorkbook = () => {
        const wb = new Workbook(WorkbookFormat.Excel2007);
        return wb;
    }

    const [clients, setClients] = useState(null);
    const [error, setError] = useState("");
    const [form, setForm] = useState(initForm);
    const [loading, setLoading] = useState(false);

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    useEffect(() => {
        if (form.exportFrom && form.exportTo && form.exportFrom?.getFullYear() == reportYear && form.exportTo?.getFullYear() == reportYear) {
            if (form.exportFrom < form.exportTo) {
                const dates = { reportYear: reportYear, exportFrom: form.exportFrom, exportTo: form.exportTo };
                dates.exportFrom = dates.exportFrom.setTime(dates.exportFrom.getTime() + (1 * 60 * 60 * 1000));
                dates.exportTo = dates.exportTo.setTime(dates.exportTo.getTime() + (1 * 60 * 60 * 1000));
                getData(dates);
            }
            else {
                setClients(null);
                setError("Das Datum im 'Von' Feld muss kleiner sein, als das Datum im 'Bis' Feld");
            }
        }
        else {
            setClients(null);
        }
    }, [form]);

    const getData = (dates) => {
        getClientsByDates(dates, userToken)
            .then((response) => {
                setClients(response.data);
            })
            .catch((err) => {
                console.log(err);
                setClients([]);
            })
    }

    const handleChange = (e) => {
        if (e.target.value) {
            setForm({ ...form, [e.target.name]: e.target.value.toDate() });
        }
        else {
            setForm({ ...form, [e.target.name]: null })
        }

        if (error) {
            setError("");
        }
    }

    async function handleClick(e) {
        if (form.exportFrom && form.exportTo) {
            if (clients?.length > 0) {
                setLoading(true);
                const dates = { ...form, reportYear: reportYear };
                dates.exportFrom = dates.exportFrom.setTime(dates.exportFrom.getTime() + (1 * 60 * 60 * 1000));
                dates.exportTo = dates.exportTo.setTime(dates.exportTo.getTime() + (1 * 60 * 60 * 1000));
                axios.post(`${apiConfig.rest}/user/data/clientExport`, { dates }, config)
                    .then((res) => {
                        const wb = fillExcel(res.data);
                        workbookSave(wb)
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    })
            }
            else {
                setError("Ein Export ohne Klient*innendaten ist nicht möglich!");
            }
        }
        else {
            setError("Geben sie bitte ein Datum in beide Felder ein!");
        }
    }

    function fillExcel(data) {
        const wb = createWorkbook();
        const ws = wb.worksheets().add('Klienten');
        let sociodemographicConfig;

        if (user.userProject.projectType.id === 2 && user.userProject.projectOffer.projectOfferType?.toLowerCase().includes("freizeit")) {
            sociodemographicConfig = formConfig.dataCollectionLeisureTime.containers.find((container) => container.header === "Soziodemografische Angaben").elements;
        }
        else {
            sociodemographicConfig = formConfig.dataCollection.containers.find((container) => container.header === "Soziodemografische Angaben").elements;
        }
        const tableSelectConfig = getTableSelectConfig();
        let maxUnderageChildren = null;

        if (user.userProject.projectType.id !== 2) {
            maxUnderageChildren = data.reduce(function (prev, current) {
                return (prev && prev.householdUnderageChildren > current.householdUnderageChildren) ? prev : current
            })
        }

        fillFieldHeaders(ws, data, sociodemographicConfig, tableSelectConfig, maxUnderageChildren);

        const reportYearCell = ws.rows(0).cells(0);
        reportYearCell.value = "Leistungsjahr: " + reportYear;
        reportYearCell.cellFormat.font.bold = true;

        fillClientData(ws, data, sociodemographicConfig, tableSelectConfig, maxUnderageChildren);

        return wb;
    }

    function fillClientData(ws, data, sociodemographicConfig, tableSelectConfig, maxUnderageChildren) {
        const selectConfig = formConfig.dataCollection.selectConfigUserData;
        const baseDataValues = ["lastName", "firstName", "birthday", "age", "addressStreet", "addressCity", "phone", "projectLocation", "mail", "extraField1", "extraField2"];

        if (user.userProject.projectType.id === 1) {
            baseDataValues.splice(baseDataValues.length - 3, 1);
        }

        if (user.userProject.projectType.id === 2) {
            baseDataValues.splice(2, 1);
            baseDataValues.splice(baseDataValues.length - 2, 2);
        }

        if (user.userProject.projectType.id === 3) {
            baseDataValues.splice(baseDataValues.length - 4, 4);
            baseDataValues.push("arranger", "extraField1", "consultationComplete");
        }

        for (let row = 0; row < data.length; row++) {
            let column = 0;

            for (let i = 0; i < baseDataValues.length; i++) {
                const cell = ws.rows(3 + row).cells(column);
                if (baseDataValues[i] === "age" && user.userProject.projectType.id !== 2) {
                    cell.value = data[row].birthday ? getAge(data[row].birthday) : "/";
                }
                else if (baseDataValues[i] === "birthday") {
                    cell.value = data[row].birthday ? dayjs(data[row].birthday).format('DD.MM.YYYY') : "/";
                }
                else if(baseDataValues[i] === "consultationComplete") {
                    cell.value = data[row].consultationComplete ? "Ja" : "Nein";
                }
                else {
                    cell.value = data[row][baseDataValues[i]] ? data[row][baseDataValues[i]] : "/";
                }
                column++;
            }

            for (let i = 0; i < sociodemographicConfig.length; i++) {
                const cell = ws.rows(3 + row).cells(column);
                if (sociodemographicConfig[i].specialTypeField === specialTypes.WITHDISPLAYNUMBEROFFIELDS) {
                    for (let j = 0; j < maxUnderageChildren.householdUnderageChildren; j++) {
                        const ageCell = ws.rows(3 + row).cells(column);
                        ageCell.value = data[row].underageChildrenAges[j] ? data[row].underageChildrenAges[j] : "/";
                        column++;
                    }
                }
                else {
                    switch (sociodemographicConfig[i].inputType) {
                        case inputTypes.SELECT:
                            const selection = selectConfig[sociodemographicConfig[i].value].find(obj => obj.id === data[row][sociodemographicConfig[i].value])
                            cell.value = selection.value;
                            column++;
                            break;

                        case inputTypes.CHECKBOX:
                            cell.value = data[row][sociodemographicConfig[i].value] ? "Ja" : "Nein";
                            column++;
                            break;

                        case inputTypes.NUMBER:
                            cell.value = data[row][sociodemographicConfig[i].value] ? data[row][sociodemographicConfig[i].value].toString() : "/";
                            column++;
                            break;

                        case inputTypes.DATE:
                            cell.value = data[row][sociodemographicConfig[i].value] ? dayjs(data[row][sociodemographicConfig[i].value]).format('DD.MM.YYYY') : "/";
                            column++;
                            break;

                        default:
                            cell.value = data[row][sociodemographicConfig[i].value] ? data[row][sociodemographicConfig[i].value] : "/";
                            column++;
                    }
                }
            }

            if (user.userProject.projectType.id !== 2) {
                for (let i = 0; i < tableSelectConfig.problemAreas.id.length; i++) {
                    const cell = ws.rows(3 + row).cells(column);
                    const count = data[row].countsProblemAreas?.find(obj => obj.problemAreaId === tableSelectConfig.problemAreas.id[i].id);

                    cell.value = count ? "Ja" : "/";
                    column++;
                }

                for (let i = 0; i < tableSelectConfig.objectives.id.length; i++) {
                    const cell = ws.rows(3 + row).cells(column);
                    const count = data[row].countsObjectives?.find(obj => obj.objectivesId === tableSelectConfig.objectives.id[i].id);

                    cell.value = count ? count.count : 0;
                    column++;
                }

                for (let i = 0; i < tableSelectConfig.services.id.length; i++) {
                    const cell = ws.rows(3 + row).cells(column);
                    const count = data[row].countsServices?.find(obj => obj.serviceId === tableSelectConfig.services.id[i].id);

                    cell.value = count ? count.count : 0;
                    column++;
                }
            }
            else {
                for (let i = 0; i < tableSelectConfig.services.service.length; i++) {
                    const cell = ws.rows(3 + row).cells(column);
                    const count = data[row].countsServices?.find(obj => obj.serviceId === tableSelectConfig.services.service[i].id);

                    cell.value = count ? count.count : 0;
                    column++;
                }
            }

            const offerType = user.userProject.projectOffer.projectOfferType.toLowerCase()
            if (offerType.includes('notuebernachtung') || offerType.includes('notübernachtung')) {
                const nightCount = data[row].countsServices?.reduce((n, { numberOfNights }) => n + numberOfNights, 0);

                const cell = ws.rows(3 + row).cells(column);
                cell.value = nightCount ? nightCount : 0;

                column++;
            }
        }
    }

    function fillFieldHeaders(ws, data, sociodemographicConfig, tableSelectConfig, maxUnderageChildren) {
        const baseDataHeaders = ["Name", "Vorname", "Geburtsdatum", "Alter", "Straße + Hausnummer", "Wohnort", "Telefon", "Standort", "E-Mail", "Textfeld1", "Textfeld2"];
        let column = 0;
        let headerStart = 0;

        if (user.userProject.projectType.id === 1) {
            baseDataHeaders.splice(baseDataHeaders.length - 3, 1);
        }

        if (user.userProject.projectType.id === 2) {
            baseDataHeaders.splice(2, 1);
            baseDataHeaders.splice(baseDataHeaders.length - 2, 2);
        }

        if (user.userProject.projectType.id === 3) {
            baseDataHeaders.splice(baseDataHeaders.length - 4, 4);
            baseDataHeaders.push("Bearbeiter/Bezugsberater*innen", "Freies Textfeld", "Beratung abgeschlossen");
        }

        for (let i = 0; i < baseDataHeaders.length; i++) {
            const cell = ws.rows(2).cells(i);
            cell.value = baseDataHeaders[i];
            cell.cellFormat.font.bold = true;

            ws.columns(i).width = 7000;
            column++;
        }

        const baseDataHeader = ws.mergedCellsRegions().add(1, headerStart, 1, column - 1);
        baseDataHeader.value = "Allgemeine Stammdaten";
        baseDataHeader.cellFormat.font.bold = true;
        baseDataHeader.cellFormat.fill = CellFill.createSolidFill("#F775FD");

        headerStart = column;
        for (let i = 0; i < sociodemographicConfig.length; i++) {
            if (sociodemographicConfig[i].specialTypeField === specialTypes.WITHDISPLAYNUMBEROFFIELDS && sociodemographicConfig[i].value === "underageChildrenAges") {
                for (let j = 0; j < maxUnderageChildren.householdUnderageChildren; j++) {
                    const cell = ws.rows(2).cells(column);
                    cell.value = "Alter Kind " + (j + 1);
                    cell.cellFormat.font.bold = true;

                    ws.columns(column).width = 7000;
                    column++;
                }
            }
            else {
                const cell = ws.rows(2).cells(column);
                cell.value = sociodemographicConfig[i].name;
                cell.cellFormat.font.bold = true;

                ws.columns(column).width = 7000;
                column++;
            }
        }
        const sociodemographicHeader = ws.mergedCellsRegions().add(1, headerStart, 1, column - 1);

        sociodemographicHeader.value = "Soziodemografische Daten";
        sociodemographicHeader.cellFormat.font.bold = true;
        sociodemographicHeader.cellFormat.fill = CellFill.createSolidFill("#C4FFA2");

        if (user.userProject.projectType.id !== 2) {
            headerStart = column;
            for (let i = 0; i < tableSelectConfig.problemAreas.id.length; i++) {
                const cell = ws.rows(2).cells(column);
                cell.value = `P${i + 1} ${tableSelectConfig.problemAreas.id[i].value}`;
                cell.cellFormat.font.bold = true;

                ws.columns(column).width = 7000;
                column++;
            }
            const problemAreasHeader = ws.mergedCellsRegions().add(1, headerStart, 1, column - 1);

            problemAreasHeader.value = "Problemlagen";
            problemAreasHeader.cellFormat.font.bold = true;
            problemAreasHeader.cellFormat.fill = CellFill.createSolidFill("#A8C5FF");

            headerStart = column;
            for (let i = 0; i < tableSelectConfig.objectives.id.length; i++) {
                const cell = ws.rows(2).cells(column);
                cell.value = `Z${i + 1} ${tableSelectConfig.objectives.id[i].value}`;
                cell.cellFormat.font.bold = true;

                ws.columns(column).width = 7000;
                column++;
            }
            const objectivesHeader = ws.mergedCellsRegions().add(1, headerStart, 1, column - 1);

            objectivesHeader.value = "Anzahl Zielerreichungen";
            objectivesHeader.cellFormat.font.bold = true;
            objectivesHeader.cellFormat.fill = CellFill.createSolidFill("#F9F0AA");
        }

        headerStart = column;
        for (let i = 0; i < (user.userProject.projectType.id === 2 ? tableSelectConfig.services.service.length : tableSelectConfig.services.id.length); i++) {
            const cell = ws.rows(2).cells(column);
            cell.value = `L${i + 1} ${user.userProject.projectType.id === 2 ? tableSelectConfig.services.service[i].value : tableSelectConfig.services.id[i].value}`;
            cell.cellFormat.font.bold = true;

            ws.columns(column).width = 7000;
            column++;
        }
        const servicesHeader = ws.mergedCellsRegions().add(1, headerStart, 1, column - 1);

        servicesHeader.value = "Anzahl Leistungen";
        servicesHeader.cellFormat.font.bold = true;
        servicesHeader.cellFormat.fill = CellFill.createSolidFill("#FF9B79");

        const offerType = user.userProject.projectOffer.projectOfferType.toLowerCase()
        if (offerType.includes('notuebernachtung') || offerType.includes('notübernachtung')) {
            const cell = ws.rows(2).cells(column);
            const colorCell = ws.rows(1).cells(column);

            cell.value = "Anzahl Übernachtungen";
            cell.cellFormat.font.bold = true;
            colorCell.cellFormat.fill = CellFill.createSolidFill("#FF9B79");

            ws.columns(column).width = 7000;
            column++;
        }
    }

    const workbookSave = (workbook) => {
        ExcelUtility.save(workbook, "Klienten").then(
            (f) => {
                setLoading(false);
                console.log("Saved:" + f);
            },
            (err) => {
                setLoading(false);
                console.error("ExcelUtility.Save Error:" + err);
            }
        );
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age.toString();
    }

    function getTableSelectConfig() {
        if (user.userProject.projectType.id !== 1) {
            return formConfig.dataCollection.tableSelectConfig;
        }

        const offerType = user.userProject.projectOffer.projectOfferType.toLowerCase()
        if (offerType.includes('notuebernachtung fuer familien') || offerType.includes('notübernachtung für familien')) {
            return formConfig.dataCollection.tableSelectOvernightFamilyConfig;
        }
        else if (offerType.includes('notuebernachtung') || offerType.includes('notübernachtung')) {
            return formConfig.dataCollection.tableSelectOvernightConfig;
        }
        else if (offerType.includes('housing first')) {
            return formConfig.dataCollection.tableSelectHouseVisitConfig;
        }
        else if (offerType.includes('medizinische versorgung')) {
            return formConfig.dataCollection.tableSelectMedicalCareConfig;
        }
        else if (offerType.includes('psychologische beratungsstelle')) {
            return formConfig.dataCollection.tableSelectPsychologicalCounselingConfig;
        }
        else {
            return formConfig.dataCollection.tableSelectConfig;
        }
    }

    return (
        <div id="excel-export">
            <Headline name="Excel-Bericht zur projektinternen Verwendung" />
            <div className="content">
                <div id="excel-form-content">
                    <Form>
                        <Datepicker label="Von" value={dayjs(form.exportFrom)} selected={dayjs(form.exportFrom)} minDate={minDate} maxDate={maxDate} name="exportFrom" onChange={handleChange} />
                        <p>-</p>
                        <Datepicker label="Bis" value={dayjs(form.exportTo)} selected={dayjs(form.exportTo)} minDate={minDate} maxDate={maxDate} name="exportTo" onChange={handleChange} />
                    </Form>
                    {loading ? (
                        <BeatLoader loading={loading} size={11} color="#009bdc" />
                    ) : (
                        <Button sx={{ textTransform: 'none', borderRadius: '0', height: '3rem', marginTop: '0.4rem' }} className="dwbo-btn-purple" onClick={handleClick} variant="contained">Excel exportieren</Button>
                    )}
                </div>
                {error &&
                    <Alert variant="outlined" severity="error">{error}</Alert>
                }
                <DataTable clients={clients} />
            </div>
        </div>
    );
}

const DataTable = ({ clients }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Klient*innen',
                disableSortBy: true,
                columns: [
                    {
                        Header: 'Nachname',
                        accessor: 'lastName'
                    },
                    {
                        Header: 'Vorname',
                        accessor: 'firstName'
                    },
                    {
                        Header: 'Erstellt am',
                        accessor: 'createdAt',
                        Cell: ({ row }) => (
                            <span>{moment(row.original.createdAt).format('DD/MM/YYYY')}</span>
                        )
                    },
                ],
            }
        ],
        []
    );

    if (clients) {
        if (clients.length > 0) {
            return (
                <Table columns={columns} data={clients} initialSortColumn={"lastName"} />
            )
        }
        else {
            return (
                <p style={{ marginTop: '1rem' }}>Keine Klient*innen gefunden</p>
            )
        }
    }
    else {
        return;
    }
}

export default ClientExport;