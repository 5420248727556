import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiConfig from "../config/config";
import { Alert, TextField, Button } from "@mui/material";
import PasswordChecklist from "react-password-checklist";
import * as Icons from 'react-icons/fa'
import Headline from "../components/Headline";
import { updateUserPassword } from "../features/admin/userManagementActions";

const initForm = {
    userPassword: "",
    userPasswordConfirm: ""
}

const initAlert = {
    type: "",
    message: ""
}

let formValid = false;

/**
 * @author Elian Plaschnick
 */
const ResetPassword = () => {
    const { resetToken } = useParams();

    const [userId, setUserId] = useState("");
    const [form, setForm] = useState(initForm);
    const [alert, setAlert] = useState(initAlert);
    const [buttonClass, setButtonClass] = useState("");

    useLayoutEffect(() => {
        axios.get(`${apiConfig.rest}/user/reset-password`, { params: { resetToken: resetToken } })
            .then((res) => {
                if (res.status === 200 && res.data._id) {
                    setUserId(res.data._id);
                }
            })
            .catch((err) => {
                setAlert({ type: "error", message: err.response.data.message });
            })
    }, []);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formValid) {
            const updateUser = { userPassword: form.userPassword, userResetPasswordToken: resetToken }
            updateUserPassword(userId, updateUser)
                .then(res => {
                    if (res.status === 200) {
                        setAlert({ type: "success", message: "Ihr Passwort wurde erfolgreich geändert" });
                    }
                })
        }
        else {
            setButtonClass("shake-button");
        }
    }

    const removeButtonClass = () => {
        setButtonClass("");
    }

    return (
        <div className="forgot-password">
            <Headline name="Passwort zurücksetzen" />
            {!alert.message ? (
                <form onSubmit={handleSubmit}>
                    <div>
                        <h6>Bitte geben Sie ein neues Passwort ein.</h6>

                        <TextField
                            label="Neues Passwort"
                            variant="outlined"
                            name="userPassword"
                            type="password"
                            onChange={handleChange}
                        />

                        <TextField
                            label="Neues Passwort bestätigen"
                            variant="outlined"
                            name="userPasswordConfirm"
                            className="mt-2"
                            type="password"
                            onChange={handleChange}
                        />

                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "match"]}
                            minLength={8}
                            value={form.userPassword}
                            valueAgain={form.userPasswordConfirm}
                            onChange={(isValid) => formValid = isValid}
                            messages={{
                                minLength: "Das Passwort muss mindestens 8 Zeichen lang sein.",
                                specialChar: "Das Passwort muss ein Sonderzeichen enthalten.",
                                number: "Das Passwort muss eine Zahl enthalten.",
                                match: "Die Passwort Wiederholung muss identisch zum Passwort sein."
                            }}
                            controlId="password-rules"
                            className="password-rules mt-4"
                            style={{ lineHeight: "1em" }}
                            iconComponents={{ ValidIcon: <Icons.FaCheck size={20} />, InvalidIcon: <Icons.FaTimes size={20} /> }}
                            invalidColor="#dc3545"
                        />

                        <div className="buttons">
                            <Button variant="contained" color="error" type="reset">Zurücksetzen</Button>
                            <Button variant="contained" color="success" type="submit" className={`${buttonClass}`} onAnimationEnd={removeButtonClass}>Senden</Button>
                        </div>
                    </div>
                </form>
            ) : (
                <div>
                    <Alert severity={alert.type}>{alert.message}</Alert>
                    {alert.type === "success" &&
                        <div className="mt-4">
                            <Link to={'/login'}>
                                <Button sx={{ textTransform: "none" }} variant="contained" color="primary">Zurück zum Login</Button>
                            </Link>
                        </div>
                    }
                </div>
            )}
        </div>
    )
}

export default ResetPassword;