//import node modules and react modules
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/user/userSlice';
import moment from 'moment/moment';

import {
    Button,
    Col,
    Container,
    Modal,
    Row, 
    Accordion,
    ProgressBar
} from "react-bootstrap";
import * as Icons from "react-icons/fa";
//Import CSS
import '../../../css/App.css'
//import DWBO Sources
import Headline from '../../../components/Headline';
import { responseNotifyHandling } from '../../../components/Error';
import { getDBData, exportData } from '../../../features/admin/dataExportManagementActions';
import axios from 'axios';
import apiConfig from '../../../config/config'

const userData = 1;

//meta data initialization
const initMetaData = {
    users: {},
    projects: [{}],
    offerTypes: [{}],
    wlh: [{}],
    sh: [{}],
    bh: [{}]
};

const AdminExport = () => {
    const [metadata, setMetadata] = useState(initMetaData);
    const [isLoading, setLoading] = useState(true);
    //const [newProject, setNewProject] = useState(initCurrentProject);
    const [show, setShow] = useState(false);
    /*
    const [confirmationMethod, setConfirmationMethod] = useState();
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBtnCancel, setConfirmationBtnCancel] = useState("Abbrechen");
    const [confirmationBtnSubmit, setConfirmationBtnSubmit] = useState("Bestätigen");
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [editData, setEditData] = useState({});
    */

    //only used for delete confirmation
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const userToken = useSelector(getToken);

    const now = 50;

    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    }

    useEffect(() => {
    },[])

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const handleExport = (value) => {
        const type = value;
        axios.get(`${apiConfig.rest}/admin/api/addProjects`, config);
        /* exportData(userToken, value).then((response) => {
            let mdata = metadata;
            
            switch (type) {
                case 1: mdata.user = response.data.user;
                        break;
                default: break;
            }
            setMetadata(mdata);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}]: ${err.response.data.message}`);
        }); */
    }

    //fetched data as json from db
    useEffect(() => {
        setLoading(true);
        getData();
    }, []);

    //get export metadata
    function getData() {
        getDBData(userToken).then((response) => {
            setMetadata(response.data);
            setLoading(false);
        }).catch((err) => {
            responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}]: ${err.response.data.message}`);
        });
    }

    //schliesst edit pop-up
    function handleClose() {
        setShow(false);
    }

    return (
        <div>
            <Headline name='Datenexport' />
            <Container className="list-container">
                <Row>
                    <Col>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Administrative Daten: Benutzer</Accordion.Header>
                                <Accordion.Body>
                                    <div>Letzter Export: {metadata.users.lastExport ? moment(metadata.users.lastExport).format("DD.MM.YYYY hh:mm:ss") : ' - '} {metadata.users.lastUser ? ' von '+metadata.users.lastUser : ''}</div>
                                    <div>Anzahl Datensätze: {metadata.users.countData ? metadata.users.countData : ' - '}</div>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" size="lg"  onClick={() => handleExport(1)}>
                                            Starte den Export für Benutzer <Icons.FaChevronRight />
                                        </Button>
                                    </div>
                                    <ProgressBar now={now} label={`${now}%`} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Administrative Daten: Projekte</Accordion.Header>
                                <Accordion.Body>
                                    <div>Letzter Export: {metadata.projects.lastExport ? moment(metadata.projects.lastExport).format("DD.MM.YYYY hh:mm:ss") : ' - '} {metadata.projects.lastUser ? ' von '+metadata.projects.lastUser : ''}</div>
                                    <div>Anzahl Datensätze: {metadata.projects.countData ? metadata.projects.countData : ' - '}</div>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" size="lg"  onClick={() => handleExport(2)}>
                                            Starte den Export für Projekte <Icons.FaChevronRight />
                                        </Button>
                                    </div>
                                    <ProgressBar now={now} label={`${now}%`} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Administrative Daten: Angebotstypen</Accordion.Header>
                                <Accordion.Body>
                                    <div>Letzter Export: {metadata.offerTypes.lastExport ? moment(metadata.offerTypes.lastExport).format("DD.MM.YYYY hh:mm:ss") : ' - '} {metadata.offerTypes.lastUser ? ' von '+metadata.offerTypes.lastUser : ''}</div>
                                    <div>Anzahl Datensätze: {metadata.offerTypes.countData ? metadata.offerTypes.countData : ' - '}</div>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" size="lg"  onClick={() => handleExport(3)}>
                                            Starte den Export für Angebotstypen <Icons.FaChevronRight />
                                        </Button>
                                    </div>
                                    <ProgressBar now={now} label={`${now}%`} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="33">
                                <Accordion.Header>Wohnungslosenhilfe</Accordion.Header>
                                <Accordion.Body>
                                    Inhalt
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="43">
                                <Accordion.Header>Straffälligenhilfe</Accordion.Header>
                                <Accordion.Body>
                                    Inhalt
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="53">
                                <Accordion.Header>Behindertenhilfe</Accordion.Header>
                                <Accordion.Body>
                                    Inhalt
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Projekt bearbeiten</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default AdminExport;