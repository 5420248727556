import axios from 'axios';
import apiConfig from '../../../config/config';

export async function getDatabaseObjects(token, route) {
    const config = getConfig(token);
    return axios.get(`${apiConfig.rest}${route}`, config);
}

export async function createDatabaseObject(databaseObject, token, route) {
    const config = getConfig(token);
    return axios.post(`${apiConfig.rest}${route}`, { object: databaseObject }, config);
}

export async function updateDatabaseObject(databaseObject, token, route) {
    const config = getConfig(token);
    return axios.put(`${apiConfig.rest}${route}/${databaseObject._id}`, { object: databaseObject }, config)
}

export async function deleteDatabaseObject(databaseObject, token, route) {
    const config = getConfig(token);
    return axios.delete(`${apiConfig.rest}${route}/${databaseObject._id}`, config);
}

function getConfig(userToken) {
    const config = {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    };

    return config;
}