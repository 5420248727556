import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        selectedEvent: null,
    },
    reducers: {
        getSelectedEvent: (state, action) => {
            state.selectedEvent = action.payload;
        }
    }
})

export const getSelectedEvent = state => state.event.selectedEvent;

export const { setSelectedEvent } = eventSlice.actions;
export default eventSlice.reducer;