export const formEventSelect = {
    kindOfEvent: [
        {
            id: 1,
            value: "Keine Angabe"
        },
        {
            id: 2,
            value: "Sonstige"
        },
        {
            id: 3,
            value: "Offener Treff / Treffpunkt"
        },
        {
            id: 4,
            value: "Offene Freizeitangebote"
        },
        {
            id: 5,
            value: "Veranstaltungen und Kurse"
        },
        {
            id: 6,
            value: "Feste / Auftritte / Präsentationen"
        },
        {
            id: 7,
            value: "Reisen (mit Übernachtung)"
        },
        {
            id: 8,
            value: "Wochenendangebote und Ausflüge (ohne Übernachtung)"
        },
        {
            id: 9,
            value: "Selbsthilfe / Ehrenamtliche Initiative"
        }
    ],
    intervalOfEvent: [
        {
            id: 1,
            value: "Keine Angabe"
        },
        {
            id: 2,
            value: "Einmalige Angebote"
        },
        {
            id: 3,
            value: "Mehrfach wöchentliche Veranstaltung"
        },
        {
            id: 4,
            value: "Wöchentliche Veranstaltung"
        },
        {
            id: 5,
            value: "14-tägige Veranstaltung"
        },
        {
            id: 6,
            value: "Monatliche Veranstaltung"
        },
        {
            id: 7,
            value: "Mehrfach jährliche Veranstaltung"
        }
    ],
    offerOnWeekdays: [
        {
            id: 1,
            value: "Keine Angabe"
        },
        {
            id: 2,
            value: "An Wochentagen"
        },
        {
            id: 3,
            value: "An Wochenenden / Feiertagen"
        }
    ],
    locationOfEvent: [
        {
            id: 1,
            value: "Keine Angabe"
        },
        {
            id: 2,
            value: "In eigenen Räumen (Präsenz)"
        },
        {
            id: 3,
            value: "Außerhalb der eigenen Räume (Präsenz)"
        },
        {
            id: 4,
            value: "Online"
        },
        {
            id: 5,
            value: "Telefon"
        },
        {
            id: 6,
            value: "Post"
        }
    ],
    mainContentOfEvent: [
        {
            id: 1,
            value: "keine Angabe"
        },
        {
            id: 2,
            value: "In Bildungsangeboten"
        },
        {
            id: 3,
            value: "Im kulturellen Bereich"
        },
        {
            id: 4,
            value: "Im sozialinklusiven Bereich"
        },
        {
            id: 5,
            value: "Anderes"
        }
    ],
    orientationParticipants: [
        {
            id: 1,
            value: "keine Angabe"
        },
        {
            id: 2,
            value: "Offenes Angebot"
        },
        {
            id: 3,
            value: "Fester Teilnehmer*innenkreis"
        }
    ]
}