import React, { useState } from 'react';
import * as Icons from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../features/user/userActions';
import { responseNotifyHandling } from '../../../components/Error';
import { Button, Form } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { updateProject } from '../../../features/admin/projectManagementActions';
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { inputTypes } from '../../../config/configEnums';

/**
 * @author Elian Plaschnick
 */
const BasicTable = ({ databaseObject, setDatabaseObject, container }) => {

    /* const rows = [
        createData("Ehrenamtliche Mitarbeiter*innen", { value: databaseObject.projectEmployees.volunteerWorkers, name: "volunteerWorkers" }, { value: databaseObject.projectEmployees.volunteerWorkersF, name: "volunteerWorkersF" }),
        createData("davon neu hinzugekommen", { value: databaseObject.projectEmployees.volunteerWorkersNew, name: "volunteerWorkersNew" }, null),
        createData("Sonstige", { value: databaseObject.projectEmployees.otherWorkers, name: "otherWorkers" }, { value: databaseObject.projectEmployees.otherWorkersF, name: "otherWorkersF" }),
        createData("keine Angaben", { value: databaseObject.projectEmployees.workersNoInfo, name: "workersNoInfo" }, { value: databaseObject.projectEmployees.workersNoInfoF, name: "workersNoInfoF" }),
    ];

    function createData(
        employee,
        employeeAmount,
        female
    ) {
        return { employee, employeeAmount, female };
    } */

    const handleChange = (e) => {
        setDatabaseObject({ ...databaseObject, [e.target.name]: e.target.value });
    }

    return (
        <div style={{ position: "relative" }}>
            {container.header && (
                <div className='table-header'>
                    <h5>{container.header}</h5>
                </div>
            )}
            {/* <div className='table-header'>
                <h5>Mitarbeiter*innen</h5>
            </div> */}
            <Paper className={`mt-3 basic-table ${container.cssClass}`} variant='outlined' square elevation={0}>
                <TableContainer sx={{ overflowY: "hidden" }}>
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {container.tableHead.map((element) => (
                                    <TableCell align={element.align} key={element.name}>
                                        {element.name}
                                    </TableCell>
                                ))
                                }
                                {/* <TableCell>Mitarbeiter*innen</TableCell>
                                <TableCell align="center">Anzahl</TableCell>
                                <TableCell align="center">davon weiblich</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {container.elements.map((element, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {element.map(cell => {
                                        switch (cell.inputType) {
                                            case inputTypes.DISPLAY:
                                                return (
                                                    <TableCell key={cell.name} scope="row">
                                                        {cell.name}
                                                    </TableCell>
                                                )

                                            case inputTypes.NUMBER:
                                                return (
                                                    <TableCell key={cell.value} sx={{ width: "7vw" }} align="center">
                                                        {cell.value !== null &&
                                                            <input
                                                                type="number"
                                                                className='default-input'
                                                                onWheel={(e) => e.target.blur()}
                                                                value={databaseObject[cell.value] || ""}
                                                                name={cell.value}
                                                                onChange={handleChange}
                                                            />
                                                        }
                                                    </TableCell>
                                                )

                                            default: return null;
                                        }
                                    })
                                    }
                                    {/* <TableCell scope="row">
                                        {element.name}
                                    </TableCell>
                                    <TableCell sx={{ width: "7vw" }} align="center">
                                        <input
                                            type="number"
                                            className='default-input'
                                            onWheel={(e) => e.target.blur()}
                                            value={databaseObject[element.value]}
                                            name={element.value}
                                            onChange={handleChange}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ width: "7vw" }} align="center">
                                        {element.female !== null &&
                                            <input
                                                type="number"
                                                className='default-input'
                                                onWheel={(e) => e.target.blur()}
                                                value={element.female.value}
                                                name={element.female.name}
                                                onChange={handleChange} />
                                        }
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );

}

export default BasicTable;